import { notify } from "@general-backoffice/core";
import { useSelector, useDispatch } from "react-redux";
import { getStrapiFileId } from "@libraries/strapi-4-utils";

import { setStore as setStoreState } from "../../../redux/reducers/session/actions";
import {storeRequests, uploadFile} from "../../../api/Api"
import useStrapi4GetOne from "../../hooks/useStrapi4GetOne";

// import { submitLocalizations } from "../../utils/localizations";
import handleError from "../../utils/handleError";

const isDevelopment = process.env.NODE_ENV === 'development'

const useStore = (id, props) => {
  const { params, onLoadError } = props || {}
  const dispatch = useDispatch()
  const state = useSelector(({ sessionState }) => sessionState)

  const {
    item: store,
    setItem: setStore,
    update: baseUpdate,
    ...other // isLoading, setLoading, load, remove
  } = useStrapi4GetOne({
    id,
    params,
    loadItem: loadStore,
    submitItem: submitStore,
  })


  const update = () => baseUpdate()
    .then(async store => {
      const [stores = []] = await storeRequests.getList()
      console.log(stores.find(s => s.id === state.store.id) || stores[0], stores)
      dispatch(setStoreState(stores.find(s => s.id === state.store.id) || stores[0], stores))
      return store
    })


  return {
    store,
    setStore,
    update,
    ...other
  }
}


export const loadStore = async (id, params) => {
  try {
    return parseInt(id) ?
      await storeRequests.get(id, params)
      :
      await storeRequests.getByIdentifier(id, params)
  } catch (e) {
    handleError(e?.message || "Error al cargar la tienda")
  }
}

export const submitStore = async store => {
  const isNew = !store?.id
  const errMsg = `${isNew ? 'Creación' : 'Modificación'} de tienda fallida`
  if (!store) throw new Error(errMsg)

  try {
    const {
      id,
      // social_networks,
      publishedAt,
      // finishedStripeOnboarding,
      logo,
      banner,
      image,
      favicon,
      product_options,
      template,
      // localizations, // name, aboutUs, refunds, shippingDetails, description
      ...props //, email // name, aboutUs, refunds, shippingDetails, description
    } = store

    const body = {
      // publishedAt: finishedStripeOnboarding ? publishedAt : null,
      publishedAt: publishedAt,
      product_options: product_options && product_options.map(p_o => p_o?.id || p_o),
      logo: await getStrapiFileId(logo, uploadFile),
      banner: await getStrapiFileId(banner, uploadFile),
      image: await getStrapiFileId(image, uploadFile),
      favicon: await getStrapiFileId(favicon, uploadFile),
      // template: 1,
      ...props
    }

    const [res] = isNew ?
      await storeRequests.create(body)
      :
      await storeRequests.update(id, body)

    // Update or create every localization needed
    // const defaultId = isNew ? res.id : id
    // if(localizations) await submitLocalizations(localizations, defaultId, storeRequests.update, storeRequests.localize)

    notify.success(`${isNew ? 'Creación' : 'Modificación'} de tienda finalizada`)
    return res
  } catch (e) {
    handleError(e?.message || errMsg)
  }
}

export const getOnboardingLink = async (id, returnUrl) => {
  try {
     return await storeRequests.getOnboardingLink(id, isDevelopment ? 'https://www.google.com' : returnUrl)
  } catch (e) {
    handleError(e?.message || "Error al cargar el enlace de onboarding")
  }
}

export const checkOnboardingStatus = async id => {
  try {
    return await storeRequests.checkOnboardingStatus(id)
  } catch (e) {
    handleError(e?.message || "Error al cargar el estado de onboarding")
  }
}


export default useStore;