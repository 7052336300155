import React from "react";
import { Container } from "reactstrap";
import { Footer, RoutesInside, Header } from "@general-backoffice/core";
import logoLight from "./../../media/branding/logo-light.png"

const Auth = () => {
  return (
    <Container fluid className="bg-default p-0 m-0 min-vh-100">
      <Header.Auth logoSrc={logoLight}/>
      <RoutesInside/>
      <Footer.Auth/>
    </Container>
  );
}

export default Auth;