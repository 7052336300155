import React from "react";
import { TableOutput, Table, TableCard } from "@general-backoffice/core";
import {useNavigate} from "react-router";
import { Card } from "reactstrap";
import _ from "lodash"

import AddButtonChildren from "../shared/AddButtonChildren";


const CategoriesTableCard = ({ categories, setCategoryToUpdate, setCategoryToDelete, defaultCategory, isLoading, pagination, goToPage, changePageSize, sort, changeSort }) => {
  const columns = [
    {
      name: "Categoria",
      formattedContent: ({ image, name }) => image ?
        <TableOutput.ImageText src={image?.thumbnail?.url || image.url} alt={image.name} text={name}/>
      :
        name,
      isTh: true,
      sort: d => {
        console.log('dddd', d)
        changeSort('name', d)
      },
      sortStatus: sort?.name
    },
    {
      name: "Banner",
      formattedContent: ({ banner }) => banner &&
        <TableOutput.Image src={banner?.formats?.thumbnail?.url || banner.url} alt={banner.name}/>
    },
    {
      name: "Productos",
      formattedContent: ({ products }) => products?.length
    },
    {
      name: "",
      formattedContent: category => {
        return <TableOutput.CustomActions
          actions={[
            {
              icon: <i className="fas fa-edit"/>,
              onClick: () => setCategoryToUpdate(_.cloneDeep(category)),
              description: "Editar categoria"
            },
            {
              icon: <i className="fas fa-trash"/>,
              onClick: () => setCategoryToDelete(_.cloneDeep(category)),
              description: "Eliminar categoriay"
            }
          ]}
        />
      }
    }
  ]


  return (
    <Card className="overflow-hidden">
      <TableCard.Header
        title="Categorias"
        buttons={[
          { children: <AddButtonChildren children="Categoria"/>, onClick: () => setCategoryToUpdate(defaultCategory) }
        ]}
        isLoading={isLoading}
      />
      <Table
        columns={columns}
        rows={categories}
        onRowClick={category => setCategoryToUpdate(_.cloneDeep(category))}
        isLoading={isLoading}
        noContentMessage="No se han encontrado categorias"
        rowHeight={100}
        rowAmount={pagination?.pageSize}
      />
      <TableCard.Footer
        amount={pagination?.pageSize}
        setAmount={changePageSize}
        totalEntries={pagination?.total}
        page={pagination?.page-1}
        onChangePage={page => goToPage(page+1)}
        isLoading={isLoading}
      />
    </Card>
  );
}

export default CategoriesTableCard;