import React, { useEffect, useMemo } from "react";
import { FormInput } from "@general-backoffice/core/index";

import useCategories from "./hooks/useCategories";

const CategorySelect = ({ category, setCategory, store, ...props }) => {
  const { categories, changeFilters, isLoading } = useCategories({
    filters: { 'filters[store]': store },
    depsToReset: [store],
    autoLoad: !!store
    // onLoadError: () => navigate('/dashboard')
  })


  const options = useMemo(() =>
    (categories || []).map(category => ({
      value: category.id,
      label: category.name,
      data: category
    }))
  , [categories])


  const selectedCategory = category && options.find(({value}) => `${value}` === `${category.id}`)

  // Set category to null if not found
  // useEffect(() => {
  //   if(category && !selectedCategory) setCategory(null)
  // }, [!!category, !selectedCategory])

  return (
    <FormInput.Select
      label="Categoría"
      {...props}
      onChange={option => setCategory(option?.data || null)}
      options={options}
      value={selectedCategory}
      isLoading={!store}
      isDisabled={isLoading || props.isDisabled || !store}
      isClearable
    />

  );
}

export default CategorySelect;