import React, { useState, useMemo, useEffect } from "react";
import {Card, CardBody, Form, Row, Col, NavLink, Collapse, FormGroup, Button} from "reactstrap";
import CardHeader from "@general-backoffice/core/lib/components/card/CardHeader";
import { FormInput } from "@general-backoffice/core/index";
import { useMultiFileInput } from "@libraries/strapi-4-utils"

// import useLang from "../hooks/useLang";
import useForm from "../hooks/useForm";



const BaseProductDetailsCard = ({ baseProduct, setBaseProduct, updateBaseProduct, isLoading }) => {
  const {
    id,
    name,
    description,
    images,
    basePrice,
    // products,
    product_options,
    publishedAt,
    // locale,
    // localizations
  } = baseProduct || {}

  const { files: imagesFiles } = useMultiFileInput(images, 'thumbnail')

  // const {
  //   lang,
  //   isLoading: isLoadingLang
  // } = useLang({
  //   loaded: !!baseProduct,
  //   locale,
  //   setLocale: locale => handleInputChange('locale', locale)
  // })

  // const localization = localizations?.[lang]
  // const { name, description } = localization || {}

  const {
    handleInputEvent,
    handleInputChange,
    // handleLocalizedInputEvent
  } = useForm(setBaseProduct, /*lang*/)


  const areLoading = isLoading /*|| isLoadingLang*/
  return (
    <Card className="overflow-hidden">
      <CardHeader
        title={id ? name : 'Nuevo producto base'}
        subtitle={baseProduct?.name}
        isLoading={areLoading}
        buttons={[{
          children: "Guardar",
          disabled: areLoading,
          form: "baseProductDetails",
          type: 'submit'
        }]}
      />
      <CardBody>
        <Form
          id="baseProductDetails"
          onSubmit={e => {
            e.preventDefault()
            updateBaseProduct()
          }}
        >

          <Row>
            <Col xs={12} sm={6} md={4}>
              <FormInput.Input
                name="name"
                label="Nombre"
                value={name}
                // onChange={handleLocalizedInputEvent}
                onChange={handleInputEvent}
                disabled={areLoading}
                required
              />
            </Col>
            <Col xs={12} sm={6} md={4}>
              <FormInput.Price
                name="basePrice"
                label="Precio base"
                value={basePrice && parseFloat(basePrice)}
                onChange={e =>
                  handleInputChange('basePrice', +parseFloat(e.target.value).toFixed(2))
                }
                disabled={areLoading}
                required
              />
            </Col>
            {id &&
              <Col>
                <FormInput.Switch
                  onChange={({ target: { checked } }) => handleInputChange("publishedAt", checked ? new Date().toISOString() : null)}
                  checked={!!publishedAt}
                  name="publishedAt"
                  label="Visible"
                  labels={["Yes", "No"]}
                  disabled={areLoading}
                />
              </Col>
            }
          </Row>

          <Row>
            <Col xs={12} md={6} lg={8}>
              <FormInput.Quill
                label="Descripción"
                // key={description}
                value={description || ""}
                onChange={value => handleInputChange('description', value)}
                style={{ maxHeight: 200 }}
                readOnly={areLoading}
              />
              {/*<FormInput.Input*/}
              {/*  type="textarea"*/}
              {/*  rows="7"*/}
              {/*  name="description"*/}
              {/*  label="Descripción"*/}
              {/*  value={description}*/}
              {/*  onChange={handleInputEvent}*/}
              {/*  disabled={areLoading}*/}
              {/*/>*/}
            </Col>
            <Col xs={12} md={6} lg={4}>
              <FormInput.MultiDropzone
                label="Imagenes"
                accept="image/*"
                files={imagesFiles}
                onDrop={files => handleInputChange('images', files)}
                buttons={[
                  {
                    children: <i className="fas fa-trash"/>,
                    color: "danger",
                    onClick: e => {
                      const imagesCopy = [...images]
                      imagesCopy.splice(e.target.index, 1)
                      handleInputChange('images', imagesCopy)
                    }
                  }
                ]}
                disabled={areLoading}
                required
              />
            </Col>
          </Row>


          <Row>
            <Col>
              {/*<FormGroup>*/}
              {/*  <div className="d-flex justify-content-between mb-2">*/}
              {/*    <label className="form-control-label">Social Networks</label>*/}
              {/*    <Button size="sm" color="primary" onClick={() => setNewSocialNetwork({})}>*/}
              {/*      <AddButtonChildren>Add</AddButtonChildren>*/}
              {/*    </Button>*/}
              {/*  </div>*/}
              {/*  {socialNetworks?.length ? socialNetworks.map(({accountName, social_network}, i) =>*/}
              {/*      <div key={i} className="d-flex justify-content-between pl-2 py-2">*/}
              {/*        <div>*/}
              {/*          <span className="text-muted">@</span><b>{accountName}</b>{" "}*/}
              {/*          {social_network && <span style={{fontSize: 12}}>({social_network?.name})</span>}*/}
              {/*        </div>*/}
              {/*        <Button*/}
              {/*          size="sm" color="danger"*/}
              {/*          onClick={() => {*/}
              {/*            const snCopy = _.cloneDeep(socialNetworks)*/}
              {/*            snCopy.splice(i, 1)*/}
              {/*            handleInputChange('socialNetworks', snCopy)*/}
              {/*          }}*/}
              {/*        ><i className="fas fa-trash"/></Button>*/}
              {/*      </div>*/}
              {/*    )*/}
              {/*    :*/}
              {/*    <div className="text-center mx-5">No social networks</div>*/}
              {/*  }*/}
              {/*</FormGroup>*/}

            </Col>
          </Row>

        </Form>
      </CardBody>
    </Card>


    // <Card className="overflow-hidden">
    //   <CardHeader
    //     title='Services photos'
    //     isLoading={areLoading}
    //     buttons={[{
    //       children: <AddButtonChildren children="Add"/>,
    //       onClick: () => setServicePhotoToUpdate({ hotel }),
    //       disabled: areLoading
    //     }]}
    //   />
    //   <CardBody className="pt-0">
    //     {servicePhotos?.length ?
    //       <Splide hasTrack={ false }>
    //         <SplideTrack className="bg-dark">
    //           {!isLoadingServicePhotos && servicePhotos.map((servicePhoto, i) =>
    //             <ServicePhotoSlide
    //               key={i}
    //               servicePhoto={servicePhoto}
    //               update={() => setServicePhotoToUpdate(_.cloneDeep(servicePhoto))}
    //               remove={() => setServicePhotoToDelete(_.cloneDeep(servicePhoto))}
    //             />
    //           )}
    //         </SplideTrack>
    //       </Splide>
    //       :
    //       <div className="text-center mx-5">No service photos</div>
    //     }
    //   </CardBody>
    // </Card>
  )
}


export default BaseProductDetailsCard;