import React from "react";
import { Loading, NotificationWrapper, PersistAndProvider } from "@general-backoffice/core";

import sessionStatePersistConfig from "./redux/reducers/session/statePersistConfig";
import sessionState from "./redux/reducers/session/state";
import {login, logout, setStore} from "./redux/reducers/session/actions";
import { storeRequests } from "./api/Api";
import Router from "./Router";
// import {checkOnboardingStatus} from "./components/stores/hooks/useStore";

const stores = {
  sessionState: {
    persistConfig: sessionStatePersistConfig,
    baseReducer: sessionState
  }
}

const afterRehydrate = async (reduxStore) => {
  const state = reduxStore.getState();
  const { sessionState: { token, user, store } } = state;
  try {
    if (!user || !token) return reduxStore.dispatch(logout())
    reduxStore.dispatch(login(token, user));

    // // This updates the onboarding status in case it has changed
    // if(store && !store.finishedStripeOnboarding)
    //   await checkOnboardingStatus(store.id)
    switch(user.role) {
      case 'Admin': {
        const [stores] = await storeRequests.getList()
        const curStore = stores.find(h => h.id === store.id) || stores?.[0]
        reduxStore.dispatch(setStore(curStore, stores))
        break;
      }
      case 'Store': {
        if(!store) return reduxStore.dispatch(logout())
        const [userStore] = await storeRequests.get(store.id)
        reduxStore.dispatch(setStore(userStore, [userStore]));
        break;
      }
      default:
        reduxStore.dispatch(logout())
    }
  } catch (e) {
    reduxStore.dispatch(logout())
  }
}


function App() {

  const loadingPage = (
    <div className="vh-100 flex-center">
      <Loading.BouncingDots size={"lg"}/>
    </div>
  )

  return (
    <PersistAndProvider stores={stores} afterRehydrate={afterRehydrate} loadingScreen={loadingPage}>
      <React.Suspense fallback={loadingPage}>
        <NotificationWrapper>
          <Router/>
        </NotificationWrapper>
      </React.Suspense>
    </PersistAndProvider>
  );
}

export default App;
