const getOptionSelectLabel = (option, type) =>
  <>
    { type === 'colors' ?
      <div style={{display: 'inline-block', marginRight: 5, height: 10, width: 10, backgroundColor: option.value}}/>
      : (type === 'icons' && option?.icon) &&
      <img src={option.icon.url} style={{height: 17, marginRight: 5}}/>
    }
    <span className="text-capitalize">{option.name}</span>
  </>

export default getOptionSelectLabel;