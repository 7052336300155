
const groupProductOptionsByProperty = (productOptions = []) =>
  Object.values(productOptions.reduce((r, prodOption) => {
    const { option, ...props } = prodOption
    const { property, ...optionProps } = option
    const cleanOption = {...props, option: optionProps}
    const key = option.property?.id
    if(!r[key])
      r[key] = {...property, productOptions: []}
    r[key]?.productOptions.push(cleanOption)
    return r
  }, {}))

export default groupProductOptionsByProperty
