import React, { useMemo } from "react";
import { FormInput } from "@general-backoffice/core/index";

import useBaseProducts from "./hooks/useBaseProducts";

const BaseProductSelect = ({ baseProduct, setBaseProduct, ...props }) => {
  const { baseProducts, isLoading } = useBaseProducts({
    populate: { 'publicationState': null }
  })

  const options = useMemo(() =>
    (baseProducts || []).map(baseProduct => ({
      value: baseProduct.id,
      label: baseProduct.name,
      data: baseProduct
    }))
  , [baseProducts])

  return (
    <FormInput.Select
      label="Producto base"
      {...props}
      onChange={option => setBaseProduct(option?.data || null)}
      options={options}
      value={baseProduct && options.find(({value}) => `${value}` === `${baseProduct.id}`)}
      isDisabled={isLoading || props.isDisabled}
      isClearable
    />
  );
}

export default BaseProductSelect;