import React from "react";
import { Modal } from "@general-backoffice/core";


const UpdateProductModal = ({ isOpen, product, close, update, isLoading }) => {
  return (
    <Modal.SaveConfirm
      isOpen={isOpen}
      isLoading={isLoading}
      toggleOpen={close}
      titleSave="Guardar cambios"
      descriptionSave={<>
        <p>Estás a punto de guardar los cambios hechos en el producto <span className="font-weight-bold">{product?.name}</span>.</p>
        <p>Esto hará retroceder la fase de validación al estado <span className="font-weight-bold">pendiente</span> y cambiará la visibilidad a <span className="font-weight-bold">oculto</span>.</p>
      </>}
      closeText="Cancelar"
      buttonSave={{ children: "Confirmar", onClick: update }}
    />
  );
}

export default UpdateProductModal;