import React from "react";
import Redirect from "./Redirect";

const index = state => [
  require("./auth").default(state),
  require("./dashboard").default(state),
  {
    path: "*",
    component: <Redirect authRedirect={"/dashboard"} unAuthRoute={"/auth"}/>,
  },
]

export default index;