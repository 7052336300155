const statusStyles = {
  pending: {status: "Pendiente", color: "light"},
  failed: {status: "Fallido", color: "light"},
  paid: {status: "Pagado", color: "warning"},
  inProduction: {status: "En producción", color: "info"},
  sent: {status: "Enviado", color: "default"},
  // received: {status: "Recibido", color: "success"},
  cancelled: {status: "Cancelado", color: "danger"},
}

export default statusStyles