import React from "react";
import { Modal, FormInput } from "@general-backoffice/core";
import { useFileInput } from "@libraries/strapi-4-utils"

import useForm from "../hooks/useForm";
// import useLang from "../hooks/useLang";


const UpdateCategoryModal = ({ category, setCategory, close, update, isLoading }) => {
  const { id, name, image, banner } = category || {}
  // const { id, image, banner, locale, localizations } = category || {}
  const { file: imageFile } = useFileInput(image)
  const { file: bannerFile } = useFileInput(banner)

  // const {
  //   lang,
  //   isLoading: isLoadingLang
  // } = useLang({
  //   loaded: !!category,
  //   locale,
  //   setLocale: locale => handleInputChange('locale', locale)
  // })

  // const localization = localizations?.[lang]
  // const { name } = localization || {}


  const {
    handleInputChange,
    handleInputEvent,
    // handleLocalizedInputEvent
  } = useForm(setCategory)
  // } = useForm(setCategory, lang)

  const handleSubmit = async (event) => {
    event.preventDefault()
    await update()
  }

  const areLoading = isLoading /*|| isLoadingLang*/
  return (
    <Modal.FormContainer
      title={!!id ? `Editar "${name}"` : "Crear categoria"}
      isOpen={!!category}
      toggleOpen={close}
      closeText="Cerrar"
      buttonSave={{ children: "Guardar", disabled: areLoading }}
      isLoading={areLoading}
      onSubmit={handleSubmit}
    >

      <FormInput.Input
        name="name"
        label="Nombre"
        value={name}
        // onChange={handleLocalizedInputEvent}
        onChange={handleInputEvent}
        disabled={areLoading}
        required
      />

      <FormInput.SingleDropzone
        label="Imagen"
        accept="image/*"
        file={imageFile}
        onDrop={file => handleInputChange('image', file)}
        disabled={areLoading}
        required
      />

      <FormInput.SingleDropzone
        label="Banner"
        accept="image/*"
        file={bannerFile}
        onDrop={file => handleInputChange('banner', file)}
        disabled={areLoading}
        required
      />


    </Modal.FormContainer>
  );
}

export default UpdateCategoryModal;