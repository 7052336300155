import { notify } from "@general-backoffice/core";

import { saleRequests } from "../../../api/Api"
import useStrapi4GetOne from "../../hooks/useStrapi4GetOne";

import handleError from "../../utils/handleError";


const useSale = (id, props) => {
  const {
    item: sale,
    setItem: setSale,
    // isLoading,
    // setLoading,
    ...other // isLoading, setLoading, load, update, remove
  } = useStrapi4GetOne({
    id,
    ...props,
    submitItem: updateSale,
    loadItem: loadSale,
  })

  // const changeStatus = status => {
  //   if(isLoading || !sale) return
  //   setLoading(true)
  //   return changeSaleStatus(sale.id, status)
  //     .finally(() => {
  //       setLoading(false)
  //     })
  //     .catch(() => null)
  // }

  return {
    sale,
    setSale,
    // changeStatus,
    // isLoading,
    ...other
  }
}


export const loadSale = async (id, params) => {
  try {
    return await saleRequests.get(id, params)
  } catch (e) {
    handleError(e?.message || "Error al cargar pedido")
  }
}

export const updateSale = async product => {
  const errMsg = 'Modificación de pedido fallida'
  if (!product?.id) throw new Error(errMsg)

  try {
    const {
      id,
      status,
      trackingId,
      // ...props
    } = product

    const body = {
      status,
      trackingId
    }

    const [res] = await saleRequests.update(id, body)

    notify.success('Modificación de producto finalizada')
    return res
  } catch (e) {
    handleError(e?.message || errMsg)
  }
}


// export const changeSaleStatus = async (id, status) => {
//   const errMsg = `Validación de pedido fallida`
//   if (!id || !status) throw new Error(errMsg)
//   try {
//     await saleRequests.update(id, { status })
//     notify.success(`Validación de pedido finalizada`)
//   } catch (e) {
//     handleError(e?.message || errMsg)
//   }
// }




export default useSale;