import React, {useMemo} from "react";
import {Card, CardBody, Form, Row, Col} from "reactstrap";
import CardHeader from "@general-backoffice/core/lib/components/card/CardHeader";
import {FormDecoration, FormInput} from "@general-backoffice/core/index";
import { useMultiFileInput } from "@libraries/strapi-4-utils";
import {useSelector} from "react-redux";

import {productOptionRequests} from "../../api/Api";

import useCategories from "../categories/hooks/useCategories";
import useProductOptions from "../productOptions/hooks/useProductOptions";

// import { useMultiFileInput } from "../hooks/useFileInput";
// import useLang from "../hooks/useLang";
import useForm from "../hooks/useForm";

import CategorySelect from "../categories/CategorySelect";
import BaseProductSelect from "../baseProducts/BaseProductSelect";

import validationConfig from "./shared/validationConfig";
import groupProductOptionsByProperty from "../productOptions/utils/groupProductOptionsByProperty";
import getOptionSelectLabel from "../options/shared/getOptionSelectLabel";


const ProductDetailsCard = ({ product, setProduct, updateProduct, changeStatus, changeVisibility, isLoading }) => {
  const user = useSelector(({ sessionState }) => sessionState.user)
  const {
    id,
    name,
    description,
    images,
    price,
    validation,
    category,
    base_product,
    store,
    // products,
    product_options,
    publishedAt,
    warning
    // locale,
    // localizations
  } = product || {}
  const { files: imagesFiles } = useMultiFileInput(images, 'thumbnail')

  // const {
  //   lang,
  //   LangSelector,
  //   isLoading: isLoadingLang
  // } = useLang({
  //   loaded: !!product,
  //   locale,
  //   setLocale: locale => handleInputChange('locale', locale)
  // })
  // const localization = localizations?.[lang]
  // const { name, description } = localizations?.[lang] || {}

  const {
    handleInputEvent,
    handleInputChange,
    // handleLocalizedInputEvent
  } = useForm(setProduct, /*lang*/)

  const status = validationConfig?.[validation]

  const {
    productOptions: rawProductOpions,
    isLoading: isLoadingRawProductOptions
  } = useProductOptions({
    filters: { 'filters[base_product]': base_product?.id },
    depsToReset: [base_product?.id],
    autoLoad: !!base_product?.id,
    // onLoadError: () => navigate('/dashboard')
  })


  const properties = useMemo(() =>
    groupProductOptionsByProperty(rawProductOpions)
  , [rawProductOpions])



  const areLoading = isLoading || isLoadingRawProductOptions /*|| isLoadingLang*/

  // Buttons
  let actions = [{
    children: "Guardar",
    disabled: areLoading,
    form: "productDetails",
    className: "ml-3",
    type: 'submit'
  }]
  if(id && user.role === 'Admin') {
    if(validation !== 'accepted')
      actions.unshift({
        children: "Acceptar",
        onClick: () => changeStatus('accepted'),
        color: 'success',
        disabled: areLoading
      })
    if(validation !== 'denied')
      actions.unshift({
        children: "Denegar",
        onClick: () => changeStatus('denied'),
        color: 'danger',
        disabled: areLoading
      })
  }

  return (
    <Card className="overflow-hidden">
      <CardHeader
        title={id ? name : 'Nuevo producto'}
        subtitle={status ?
          <span className={`text-${status.color}`}>{status.status}</span>
        :
          (isLoading && 'Cargando...')
        }
        isLoading={areLoading}
        buttons={actions}
      />
      <CardBody>
        <Form
          id="productDetails"
          onSubmit={e => {
            e.preventDefault()
            updateProduct()
          }}
        >

          {!!warning &&
            <div className="text-center mb-4 text-danger">
              <i className="fas fa-exclamation-triangle"/> {warning}
            </div>
          }

          <Row>
            <Col xs={12} sm={6} md={4}>
              <FormInput.Input
                name="name"
                label="Nombre"
                value={name}
                // onChange={handleLocalizedInputEvent}
                onChange={handleInputEvent}
                disabled={areLoading}
                required
              />
            </Col><
            Col xs={12} sm={6} md={4}>
              <FormInput.Price
                name="price"
                label="Precio"
                description={!!base_product ? `Precio base: ${base_product.basePrice} €` : 'Elige un producto base'}
                min={base_product?.basePrice}
                value={price && parseFloat(price)}
                onChange={e =>
                  handleInputChange('price', +parseFloat(e.target.value).toFixed(2))
                }
                disabled={areLoading || !base_product}
                required
              />
            </Col>
            {id &&
            <Col>
              <FormInput.Switch
                onChange={({ target: { checked } }) => changeVisibility(checked)}
                checked={validation === 'accepted' && !!publishedAt}
                name="publishedAt"
                label="Visible"
                labels={["Yes", "No"]}
                error={!(validation === 'accepted') && 'Validación requerida'}
                disabled={areLoading || !(validation === 'accepted')}
              />
            </Col>}
          </Row>

          <Row>
            <Col xs={12} sm={6}>
              <CategorySelect
                label="Categoria"
                category={category}
                setCategory={category => handleInputChange('category', category)}
                store={store?.id}
                isDisabled={areLoading}
                required
              />
            </Col>
            <Col xs={12} sm={6}>
              <BaseProductSelect
                label="Producto base"
                baseProduct={base_product}
                setBaseProduct={base_product =>
                  setProduct(product => ({
                    ...product,
                    base_product,
                    product_options: []
                  }))
                }
                isDisabled={areLoading}
                required
              />
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={6} lg={8}>
              <FormInput.Quill
                label="Descripción"
                value={description || ""}
                onChange={value => handleInputChange('description', value)}
                readOnly={areLoading}
              />
            </Col>
            <Col xs={12} md={6} lg={4}>
              <FormInput.MultiDropzone
                label="Imagenes"
                accept="image/*"
                files={imagesFiles}
                onDrop={files => handleInputChange('images', files)}
                buttons={[
                  {
                    children: <i className="fas fa-trash"/>,
                    color: "danger",
                    onClick: e => {
                      const imagesCopy = [...images]
                      imagesCopy.splice(e.target.index, 1)
                      handleInputChange('images', imagesCopy)
                    }
                  }
                ]}
                disabled={areLoading}
                required
              />
            </Col>
          </Row>

          {!!properties.length && <>
            <FormDecoration.LineTitle>
              Características
            </FormDecoration.LineTitle>
            <Row>
              {properties.map(({ name, productOptions, component, selectable }, i) =>
                <Col xs={12} sm={6} lg={4} key={i}>
                  <ProductOptionsSelect
                    label={<div>
                      {name.charAt(0).toUpperCase() + name.slice(1)}
                      {selectable && <span style={{fontSize: '0.7rem', fontWeight: 'normal'}}>
                        {' '}(Seleccionable)
                      </span>}
                    </div>}
                    selectedProductOptions={product_options}
                    productOptions={productOptions}
                    setProductOptions={prodOptions => handleInputChange('product_options', prodOptions)}
                    type={component}
                    isLoading={areLoading}
                    // isDisabled={areLoading}
                  />
                </Col>
              )}
            </Row>
          </>}

        </Form>
      </CardBody>
    </Card>
  )
}



const ProductOptionsSelect = ({selectedProductOptions, productOptions, type, setProductOptions, ...props}) => {
  const prodOptsIds = productOptions.map(({id}) => id)
  const [selected, nonSelected] = selectedProductOptions.reduce((r, prodOpt) => {
    r[prodOptsIds.includes(prodOpt?.id) ? 0 : 1].push(prodOpt)
    return r
  }, [[], []])

  const options = useMemo(() =>
    (productOptions || []).map(prodOpt => ({
      value: prodOpt.id,
      label: getOptionSelectLabel(prodOpt?.option, type),
      data: prodOpt
    }))
  , [productOptions])

  return (
    <FormInput.Select
      {...props}
      options={options}
      value={selected.map(prodOption => options.find(({value}) => value === prodOption.id))}
      onChange={options => setProductOptions(nonSelected.concat(options.map(v => v?.data)))}
      isClearable
      isMulti
    />
  );
}


export default ProductDetailsCard;