import React, {useCallback, useEffect, useState} from "react";
import { Container } from "reactstrap";
import {
  SidebarContainer,
  RoutesInside,
  Footer,
  Header,
  useFinalRoutes,
  notify
} from "@general-backoffice/core";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";

import {logout, setStore} from "../../redux/reducers/session/actions";

import { loadProducts } from "../../components/products/hooks/useProducts";
import { getOnboardingLink } from "../../components/stores/hooks/useStore";

import UserStoresModal from "../../components/stores/UserStoresModal";

import logo from "./../../media/branding/logo-dark-squad.png"


const Dashboard = () => {
  const navigate = useNavigate()
  const routes = useFinalRoutes();
  const { store, user } = useSelector(({ sessionState }) => sessionState)
  const [isOpenSidebar, setOpenSidebar] = useState(true)
  const [isOpenStores, setOpenStores] = useState(false)
  const dispatch = useDispatch();


  const loadAdminNotifications = useCallback(async () => {
    let notifications = []

    // Add pending products notifications
    const [pendingProducts] = await loadProducts({ 'filters[validation]': 'pending', populate: 'store' })
    if(pendingProducts.length) {
      notifications = notifications.concat(pendingProducts.map(p => ({
        onClick: () => {
          dispatch(setStore(p.store))
          navigate(`/dashboard/products/${p.identifier}`)
          notify(`Bienvenido a ${p.store.name}`)
        },
        title: <span className="font-weight-normal">El producto <b>{p.name}</b> de la tienda <b>{p.store.name}</b> requiere validación</span>,
      })))
    }

    // // Add sales notifications // TODO
    // const [pendingProducts] = await loadSales({ 'filters[validation]': 'pending', populate: 'store' })
    // if(toVerify)
    //   notifications.push({
    //     onClick: () => navigate('/dashboard/users'),
    //     title: `${toVerify} ${toVerify > 1 ? 'users need' : 'influencer needs'} verification`,
    //     description: 'You can verify their social network information from the Users tab on the sidebar clicking the corresponding users on the table.'
    //   })

    return notifications
  }, [])

  const loadStoreNotifications = useCallback(async () => {
    let notifications = []

    // Add denied products notifications
    const [deniedProducts] = await loadProducts({ 'filters[validation]': 'denied' })
    if(deniedProducts.length) {
      notifications = notifications.concat(deniedProducts.map(p => ({
        onClick: () => navigate(`/dashboard/products/${p.identifier}`),
        title: <span className="font-weight-normal">El producto <b>{p.name}</b> ha sido denegado</span>,
        description: <span className="text-danger">{p?.warning}</span>
      })))
    }

    return notifications
  }, [])

  const loadCommonNotifications = useCallback(async () => {
    let notifications = []

    // // Add stripe onboarding notifications
    // if(!store?.finishedStripeOnboarding) {
    //   try{
    //     const { url } = await getOnboardingLink(store.id, window.location.origin + '/dashboard/onboarding')
    //     notifications = notifications.concat({
    //       title: <span className="text-danger">Onboarding necesario</span>,
    //       description:
    //         <span className="font-weight-normal">
    //         Puedes iniciar el proceso mediante <a href={url} rel="noreferrer" className="text-danger text-underline font-weight-bold">este enlace</a>.
    //       </span>,
    //     })
    //   } catch(e) {}
    // }

    return notifications
  }, [store])

  const [notifications, setNotifications] = useState([])
  useEffect(() => {(async () => {
    let notifications = (await loadCommonNotifications()) || []
    if(user.role === 'Admin') notifications = notifications.concat(await loadAdminNotifications())
    if(user.role === 'Store') notifications = notifications.concat(await loadStoreNotifications())
    setNotifications(notifications)
    // eslint-disable-next-line
  })()}, [user, store])


  const ntCount = notifications?.length || [];
  const notificationsHeader = ntCount > 0 ? `Tienes ${ntCount} ${ntCount === 1 ? "notificación" : "notificaciónes"}` : "No tienes ninguna notificación"


  const sessionOptions = [
    ...(user.role === 'Admin' ? [{
      title: "Tiendas",
      onClick: () => setOpenStores(true),
      icon: "fas fa-store",
      // useDivider: true
    }] : []),
    {
      title: "Logout",
      onClick: () => dispatch(logout()),
      icon: "fas fa-running",
      useDivider: true
    }
  ]

  return (
    <React.Fragment>
      <UserStoresModal isOpen={isOpenStores} setOpen={setOpenStores}/>
      <Container fluid className="p-0 m-0 min-vh-100">
        <SidebarContainer
          routes={routes}
          isOpen={isOpenSidebar}
          setOpen={setOpenSidebar}
          logoSrc={logo}
        >
          <Header.Session
            isOpen={isOpenSidebar}
            setOpen={setOpenSidebar}
            sessionName={store?.name}
            notifications={notifications || []}
            isDark={false}
            color="secondary"
            avatarSrc={store?.logo?.url}
            sessionOptions={sessionOptions}
            sessionOptionsHeader="¡Bienvenido!"
            notificationsHeader={notificationsHeader}
          />
          <RoutesInside/>
          <Footer.Session/>
        </SidebarContainer>
      </Container>
    </React.Fragment>
  );
}

export default Dashboard;