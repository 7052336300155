import React from "react";
import { notify, Page } from "@general-backoffice/core";
import { useNavigate } from "react-router";
import { forgotPassword } from "../../../api/Api";

const ForgottenPassword = () => {
  const navigate = useNavigate()

  const onSubmit = async (email) =>
    forgotPassword(email)
      .then(() => {
        notify.success("A recovery email has been sent.")
        navigate("/auth/login")
      })
      .catch(e => notify.error(e.message))


  return <Page.ForgottenPassword
    title="Request password reset"
    lead="Please, input your email to start the password reset process"
    emailPlaceholder="Email"
    loginText="LogIn"
    invalidEmailFeedbackText="Input a valid email"
    submitText="Send recovery email"
    onSubmit={onSubmit}
    onClickLogin={() => navigate("/auth/login")}
    // registerText="SignUp"
    // onClickRegister={() => navigate("/auth/register")}
  />;
}

export default ForgottenPassword;