import React from "react";
import { TableOutput, Table, TableCard } from "@general-backoffice/core";
import {useNavigate} from "react-router";
import { Card } from "reactstrap";

import AddButtonChildren from "../shared/AddButtonChildren";
import Stars from "../shared/Stars";


const status = [
  { status: "Falta onboarding", color: "warning" },
  { status: "Visible", color: "success" },
  { status: "Oculta", color: "danger" }
]

const stripeStatus = [
  { status: "Configurado", color: "success" },
  { status: "Por configurar", color: "danger" }
]

const StoresTableCard = ({ stores, isLoading, pagination, goToPage, changePageSize, sort, changeSort }) => {
  const navigate = useNavigate();

  const columns = [
    {
      name: "Tienda",
      formattedContent: ({ logo, name }) => logo ?
        <TableOutput.ImageText src={logo?.thumbnail?.url || logo.url} alt={logo.name} text={name}/>
        :
        name,
      isTh: true,
      sort: d => changeSort('name', d),
      sortStatus: sort?.name
    },
    {
      name: "Estado",
      sort: () => null,
      // formattedContent: ({ publishedAt, finishedStripeOnboarding }) =>
      //   <TableOutput.Status {...(!finishedStripeOnboarding ? status[0] : (publishedAt ? status[1] : status[2]))}/>
      formattedContent: ({ publishedAt }) =>
        <TableOutput.Status {...(publishedAt ? status[1] : status[2])}/>
    },
    {
      name: "Imágenes",
      sort: () => null,
      formattedContent: ({ image, banner }) => (image || banner) &&
        <TableOutput.Images
          images={
            [image, banner]
              .filter(p => !!p)
              .map(p => ({src: p?.formats?.thumbnail?.url || p.url, alt: p.name}))
          }
        />
    },
    {
      name: "",
      sort: () => null,
      formattedContent: store => {
        return <TableOutput.CustomActions
          actions={[
            {
              icon: <i className="fas fa-edit"/>,
              onClick: () => navigate(`${store?.identifier}`),
              description: "Editar tienda"
            }
          ]}
        />
      }
    }
  ]


  return (
    <Card className="overflow-hidden position-relative">
      <TableCard.Header
        title="Tiendas"
        buttons={[
          { children: <AddButtonChildren children="Tienda"/>, onClick: () => navigate(`new`) }
        ]}
        isLoading={isLoading}
      />
      <Table
        columns={columns}
        rows={stores}
        onRowClick={store => navigate(`${store?.identifier}`)}
        isLoading={isLoading}
        noContentMessage="No se han encontrado tiendas"
        rowHeight={100}
        rowAmount={pagination?.pageSize}
      />
      <TableCard.Footer
        amount={pagination?.pageSize}
        setAmount={changePageSize}
        totalEntries={pagination?.total}
        page={pagination?.page-1}
        onChangePage={page => goToPage(page+1)}
        isLoading={isLoading}
      />
    </Card>
  );
}

export default StoresTableCard;