import { propertyRequests } from "../../../api/Api"
import useStrapi4GetList from "../../hooks/useStrapi4GetList";
import handleError from "../../utils/handleError";

const useProperties = props => {
  const {
    items: properties,
    ...other
  } = useStrapi4GetList({
    ...props,
    load: loadProperties,
    // parseFilters
  })

  return {
    properties,
    ...other
  }
}

export const loadProperties = async params => {
  try {
    return await propertyRequests.getList(params)
  } catch (e) {
    handleError(e?.message || "Error al cargar propiedades")
  }
}

export default useProperties;