import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Header } from "@general-backoffice/core/index";
import { useNavigate } from "react-router";

import useSales from "../../../../components/sales/hooks/useSales";
import useSale from "../../../../components/sales/hooks/useSale";

// Components
import UpdateSaleModal from "../../../../components/sales/UpdateSaleModal";
import SalesTableCard from "../../../../components/sales/SalesTableCard";


const Sales = ({ store }) => {
  const navigate = useNavigate()
  const {
    sales,
    refresh,
    isLoading: isLoadingSales,
    filters,
    changeFilters,
    goToPage,
    changePageSize,
    pagination,
    sort,
    changeSort
  } = useSales({
    filters: { 'filters[store]': store },
    sort: { createdAt: 'down'},
    pagination: true,
    onLoadError: (e) => navigate('/dashboard', { replace: true }),
    depsToReset: [store]
  })

  const {
    sale: saleToUpdate,
    setSale: setSaleToUpdate,
    update: updateSale,
    // changeStatus,
    isLoading: isUpdatingSale
  } = useSale()


  return (
    <React.Fragment>

      <UpdateSaleModal
        sale={saleToUpdate}
        setSale={setSaleToUpdate}
        close={() => setSaleToUpdate(null)}
        update={() =>
          updateSale()
            .then(async () => {
              await refresh()
              setSaleToUpdate(null)
            })
            .catch(() => null)
        }
        // changeStatus={() =>
        //   changeStatus()
        //     .then(refresh)
        //     .catch(() => null)
        // }
        isLoading={isUpdatingSale}
      />

      <Header.BreadCrumb color="secondary" isDark={false}/>
      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <SalesTableCard
              {...{
                filters,
                changeFilters,
                goToPage,
                changePageSize,
                sort,
                changeSort,
                pagination
              }}
              sales={sales}
              setSaleToUpdate={setSaleToUpdate}
              isLoading={isLoadingSales}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default Sales;