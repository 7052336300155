import React, { useState } from "react";
import {Button, Card, CardBody, Col, Container, Row} from "reactstrap";
import { Navigate, useSearchParams } from "react-router-dom";
import { notify } from "@general-backoffice/core";
import Header from "@general-backoffice/core/lib/pages/auth/components/Header";

import Api from "../../../api/Api"

const ConfirmUserRemoval = () => {
  const [searchParams] = useSearchParams()
  const [confirmed, setConfirmed] = useState(false)

  const token = searchParams.get('token')

  const confirmRemoval = () =>
    Api.post("/users/me/confirm-remove", { token })
      .then(res => {
        notify.success(res)
        setConfirmed(true)
      })
      .catch(e => notify.error(e.message))

  if(!token) return <Navigate to="/" replace />
  return <React.Fragment>
    <Header title="Confirm account removal" lead="Once removed you won't be able to recover it."/>
    <Container className="mt--7 pb-5">
      <Row className="justify-content-center">
        <Col lg="5" md="7">
          <Card className="bg-secondary border-0 mb-0">
            <CardBody className="px-lg-5 py-lg-5 flex-center">
              {!confirmed ?
                <Button color="danger" onClick={confirmRemoval}>Confirm Removal</Button>
              :
                <p>Your account has been removed successfully</p>
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  </React.Fragment>
}

export default ConfirmUserRemoval;