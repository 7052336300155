import React from "react";
import Dashboard from "../../views/layouts/Dashboard";
import { Navigate } from "react-router";

// Views
import Home from "../../views/pages/dashboard/home";
import Store from "../../views/pages/dashboard/stores/Store";
import Categories from "../../views/pages/dashboard/categories";
import OnboardingStatus from "../../views/pages/dashboard/OnboardingStatus";
import Sales from "../../views/pages/dashboard/sales/Sales";


// SubRoutes;
import storesSubroutes from "./storesSubroutes"
import productsSubroutes from "./productsSubroutes"
import baseProductsSubroutes from "./baseProductsSubroutes"



const subRoutes = state => {
  const storeSection = state?.store?.name.trim() || 'Store'
  return [
    {
      name: "Home",
      accessRoles: ['Admin'],
      path: "",
      showOnNav: true,
      icon: "fas fa-home text-default",
      component: <Home />
    },
    {
      name: "Tiendas",
      accessRoles: ['Admin'],
      path: "stores",
      showOnNav: true,
      icon: "fas fa-store text-default",
      subRoutes: storesSubroutes(state)
    },
    {
      name: "Productos base",
      accessRoles: ['Admin'],
      path: "base-products",
      showOnNav: true,
      icon: "fas fa-tshirt text-default",
      subRoutes: baseProductsSubroutes(state)
    },
    {
      name: "Ventas",
      accessRoles: ['Admin'],
      path: "sales",
      showOnNav: true,
      icon: "fas fa-hand-holding-usd text-default",
      component: <Sales />
    },


    {
      name: "Mi tienda",
      accessRoles: ['Admin', 'Store'],
      path: "my-store",
      showOnNav: storeSection,
      icon: "fas fa-store text-default",
      component: <Store identifier={state?.store?.identifier}/>
    },
    {
      name: "Mis productos",
      accessRoles: ['Admin', 'Store'],
      path: "products",
      showOnNav: storeSection,
      icon: "fas fa-tshirt text-default",
      subRoutes: productsSubroutes(state)
    },
    {
      name: "Mis categorías",
      accessRoles: ['Admin', 'Store'],
      path: "categories",
      showOnNav: storeSection,
      icon: "fas fa-th-large text-default",
      component: <Categories store={state?.store?.id}/>
    },
    {
      name: "Mis ventas",
      accessRoles: ['Admin', 'Store'],
      path: "my-sales",
      showOnNav: storeSection,
      icon: "fas fa-hand-holding-usd text-default",
      component: <Sales store={state?.store?.id}/>
    },
    // {
    //   name: "Onboarding",
    //   accessRoles: ['Admin', 'Store'],
    //   path: "onboarding",
    //   component: <OnboardingStatus />
    // }
  ]
}


const index = state => ({
  path: "/dashboard",
  component: <Dashboard/>,
  subRoutes: subRoutes(state).concat({
    path: "*",
    // accessRoles: ['Admin'],
    index: true,
    component: <Navigate to="/dashboard/my-store" replace={true}/>
  })
})

export default index;