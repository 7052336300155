import React, { useMemo } from "react";
import {FormInput} from "@general-backoffice/core/index";

const StoreSelect = ({selectedStore, stores, setStore, ...props}) => {
  const options = useMemo(() =>
      (stores || []).map(store => ({
        value: store.id,
        label: store.name,
        data: store
      }))
    , [stores])

  return (
    <FormInput.Select
      {...props}
      options={options}
      value={selectedStore && options.find(({value}) => value === selectedStore.id)}
      onChange={option => setStore(option.data)}
      isSearchable
    />
  );
}

export default StoreSelect