import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Header } from "@general-backoffice/core/index";
import { useNavigate } from "react-router";

import {storeRequests} from "../../../../api/Api";
import useStores from "../../../../components/stores/hooks/useStores";

// Components
import StoresTableCard from "../../../../components/stores/StoresTableCard"


const Stores = () => {
  const navigate = useNavigate()
  const {
    stores,
    isLoading: isLoadingStores,
    goToPage,
    changePageSize,
    pagination,
    sort,
    changeSort
  } = useStores({
    sort: true,
    pagination: true,
    onLoadError: (e) => navigate('/dashboard')
  })

  return (
    <React.Fragment>
      <Header.BreadCrumb color="secondary" isDark={false}/>
      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <StoresTableCard
              {...{
                goToPage,
                changePageSize,
                sort,
                changeSort,
                pagination
              }}
              stores={stores}
              isLoading={isLoadingStores}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default Stores;