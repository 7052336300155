import React from "react";
import { Table, Modal, notify } from "@general-backoffice/core";
import { TableOutput } from "@general-backoffice/core/index";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { setStore } from "../../redux/reducers/session/actions";
import {checkOnboardingStatus} from "./hooks/useStore";


const UserStoresModal = ({ isOpen, setOpen }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { store, stores } = useSelector(({ sessionState }) => sessionState)

  const columns = [
    {
      name: "Name",
      formattedContent: ({ logo, name }) => logo ?
        <TableOutput.ImageText src={logo?.thumbnail?.url || logo.url} alt={logo.name} text={name}/>
        : name,
      isTh: true,
    },
    {
      name: "",
      formattedContent: ({ id }) => (id === store?.id) ? <i className="fas fa-check"/> : null,
    },
  ]

  const handleClick = async newStore => {
    if (store?.id !== newStore.id) {

      // // This updates the onboarding status in case it has changed
      // if(newStore && !newStore.finishedStripeOnboarding) {
      //   const onboardingStatus = await checkOnboardingStatus(newStore.id)
      //   newStore.finishedStripeOnboarding = onboardingStatus
      // }

      dispatch(setStore(newStore))
      setOpen(false)
      navigate('/')
      notify(`Bienvenido a ${newStore.name}`)
    }
  };


  return (
    <Modal.Simple isOpen={isOpen} toggleOpen={() => setOpen(!isOpen)} title="Tiendas" closeText="Close">
      <div className="overflow-hidden mb-0 mx--4 mb--4">
        <Table
          columns={columns}
          rows={stores}
          onRowClick={handleClick}
          noContentMessage="No hay tiendas"
        />
      </div>
    </Modal.Simple>
  );
}

export default UserStoresModal;