
import { Navigate } from "react-router";
import BaseProducts from "../../views/pages/dashboard/baseProducts/BaseProducts"
import BaseProduct from "../../views/pages/dashboard/baseProducts/BaseProduct";


const subRoutes = state => [
  {
    name: "Productos base",
    path: "",
    component: <BaseProducts store={state?.store?.id}/>
  },
  {
    name: "Producto base",
    path: ":id",
    component: <BaseProduct />
  },
  {
    name: "Crear producto base",
    path: "new",
    component: <BaseProduct />
  },
  {
    path: "*",
    index: true,
    component: <Navigate to="/dashboard/base-products" replace={true}/>,
  }
]

export default subRoutes;
