import { notify } from "@general-backoffice/core";
import { getStrapiFileId } from "@libraries/strapi-4-utils";

import {baseProductRequests, uploadFile} from "../../../api/Api"
import useStrapi4GetOne from "../../hooks/useStrapi4GetOne";

// import { submitLocalizations } from "../../utils/localizations";
import handleError from "../../utils/handleError";


const useBaseProduct = (id, props) => {
  const {
    item: baseProduct,
    setItem: setBaseProduct,
    ...other // isLoading, setLoading, load, update, remove
  } = useStrapi4GetOne({
    id,
    ...props,
    loadItem: loadBaseProduct,
    submitItem: submitBaseProduct,
  })


  return {
    baseProduct,
    setBaseProduct,
    ...other
  }
}


export const loadBaseProduct = async (id, params) => {
  try {
    return await baseProductRequests.get(id, params)
  } catch (e) {
    handleError(e?.message || "Error al cargar el producto nase")
  }
}

export const submitBaseProduct = async baseProduct => {
  const isNew = !baseProduct?.id
  const errMsg = `${isNew ? 'Creación' : 'Modificación'} de producto base fallida`
  if (!baseProduct) throw new Error(errMsg)
  try {
    const {
      id,
      images,
      // product_options,
      // localizations, // name, description
      ...props // basePrice, publishedAt, // name, description
    } = baseProduct

    const body = {
      images: images && await Promise.all(images.map(p => getStrapiFileId(p, uploadFile))),
      ...props
    }

    const [res] = isNew ?
      await baseProductRequests.create(body)
      :
      await baseProductRequests.update(id, body)

    // Update or create every localization needed
    // const defaultId = isNew ? res.id : id
    // if(localizations) await submitLocalizations(localizations, defaultId, baseProductRequests.update, baseProductRequests.localize)

    notify.success(`${isNew ? 'Creación' : 'Modificación'} de producto base finalizada`)
    return res
  } catch (e) {
    handleError(e?.message || errMsg)
  }
}



export default useBaseProduct;