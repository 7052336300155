import { saleRequests } from "../../../api/Api"
import useStrapi4GetList from "../../hooks/useStrapi4GetList";
import handleError from "../../utils/handleError";

const useSales = props => {
  const {
    items: sales,
    ...other
  } = useStrapi4GetList({
    ...props,
    load: loadSales,
    // parseFilters
  })

  return {
    sales,
    ...other
  }
}

export const loadSales = async params => {
  try {
    return await saleRequests.getList(params)
  } catch (e) {
    handleError(e?.message || "Error al cargar ventas")
  }
}

export default useSales;