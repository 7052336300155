import { productOptionRequests } from "../../../api/Api"
import useStrapi4GetList from "../../hooks/useStrapi4GetList";
import handleError from "../../utils/handleError";

const useProductOptions = props => {
  const {
    items: productOptions,
    ...other
  } = useStrapi4GetList({
    ...props,
    load: loadProductOptions,
    // parseFilters
  })

  return {
    productOptions,
    ...other
  }
}

export const loadProductOptions = async params => {
  try {
    return await productOptionRequests.getList(params)
  } catch (e) {
    handleError(e?.message || "Error al cargar opciónes de producto")
  }
}

export default useProductOptions;