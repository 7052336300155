import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Header } from "@general-backoffice/core/index";
import { useNavigate } from "react-router";

import useProducts from "../../../../components/products/hooks/useProducts";

// Components
import ProductsTableCard from "../../../../components/products/ProductsTableCard"


const Products = ({ store }) => {
  const navigate = useNavigate()
  const {
    products,
    isLoading: isLoadingProducts,
    goToPage,
    changePageSize,
    pagination,
    sort,
    changeSort
  } = useProducts({
    filters: { 'filters[store]': store },
    sort: true,
    pagination: true,
    onLoadError: (e) => navigate('/dashboard', { replace: true }),
    depsToReset: [store]
  })

    console.log(products, pagination)

  return (
    <React.Fragment>
      <Header.BreadCrumb color="secondary" isDark={false}/>
      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <ProductsTableCard
              {...{
                goToPage,
                changePageSize,
                sort,
                changeSort,
                pagination
              }}
              products={products}
              isLoading={isLoadingProducts}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default Products;