
import { Navigate } from "react-router";
import Products from "../../views/pages/dashboard/products/Products"
import Product from "../../views/pages/dashboard/products/Product";


const subRoutes = state => [
  {
    name: "Productos",
    path: "",
    component: <Products store={state?.store?.id}/>
  },
  {
    name: "Producto",
    path: ":identifier",
    component: <Product />
  },
  {
    name: "Crear producto",
    path: "new",
    component: <Product />
  },
  {
    path: "*",
    index: true,
    component: <Navigate to="/dashboard/products" replace={true}/>,
  }
]

export default subRoutes;
