import React, { useMemo } from "react";
import {Card, Row, Col, CardBody, Container, Button} from "reactstrap";
import CardHeader from "@general-backoffice/core/lib/components/card/CardHeader";
import {FormInput, Loading} from "@general-backoffice/core/index";
import _ from "lodash"

import {productOptionRequests, propertyRequests} from "../../api/Api";

import useProperties from "../properties/hooks/useProperties";
import useProductOption, { submitProductOption, deleteProductOption } from "../productOptions/hooks/useProductOption";

import getOptionSelectLabel from "../options/shared/getOptionSelectLabel";
import useProductOptions from "../productOptions/hooks/useProductOptions";
import DeleteProductOptionModal from "../productOptions/DeleteProductOptionModal";


const capitalize = str => str ? str.charAt(0).toUpperCase() + str.slice(1) : str;


const BaseProductPropertiesCards = ({ baseProduct, isLoading }) => {
  const {
    productOptions: baseProductOptions = [],
    refresh: refreshBaseProductOptions,
    isLoading: isLoadingBaseProductOptions
  } = useProductOptions({
    filters: { 'filters[base_product]': baseProduct?.id },
    depsToReset: [baseProduct?.id],
    autoLoad: !!baseProduct?.id,
    populate: productOptionRequests.defaultPopulate.concat('products.store')
    // onLoadError: () => navigate('/dashboard')
  })
  const selectedOptionsIds = baseProductOptions.map(po => po?.option.id)

  const {
    properties = [],
    isLoading: isLoadingProperties
  } = useProperties({
    populate: propertyRequests.defaultPopulate.concat(['options.icon'])
  })

  const propertySelectedOptions = useMemo(() => {
    const baseObj = properties.reduce((r, p) => _.assign(r, {[p.id]: []}), {})
    return baseProductOptions.reduce((r, prodOpt) => {
      r[prodOpt.option.property.id].push(prodOpt)
      return r
    }, baseObj)
  }, [properties, baseProductOptions])

  const {
    productOption: productOptionToDelete,
    setProductOption: setProductOptionToRemove,
    remove: removeProductOption,
    isLoading: isRemovingProductOption
  } = useProductOption()


  const areLoading = isLoading || isLoadingBaseProductOptions || isLoadingProperties || isRemovingProductOption
  return <React.Fragment>

    <DeleteProductOptionModal
      productOption={productOptionToDelete}
      close={() => setProductOptionToRemove(null)}
      remove={() => {
        removeProductOption()
          .then(async () => {
            await refreshBaseProductOptions()
            setProductOptionToRemove(null)
          })
          .catch(() => null)
      }}
      isLoading={areLoading}
    />

    <Row>
      {!!properties?.length && properties.map((property, i) =>
        <Col xs={12} lg={6} key={i}>
          <Card className="overflow-hidden position-relative">
            <CardHeader
              title={capitalize(property?.name)}
              subtitle={property?.selectable && 'Seleccionable'}
              isLoading={areLoading}
            />

            <div className="position-absolute p-4" style={{top: 0, right: 0, maxWidth: '50%', width: 200}}>
              <OptionSelect
                className="mb--4"
                placeholder="Buscar..."
                setOption={option =>
                  submitProductOption({ option, base_product: baseProduct })
                    .then(() => refreshBaseProductOptions())
                }
                options={property.options.filter(o => !selectedOptionsIds.includes(o.id))}
                type={property.component}
                isDisabled={!baseProduct?.id}
              />
            </div>

            <CardBody>
              <Container fluid>
                <Row>
                  {propertySelectedOptions[property.id].map(productOption =>
                    <ProductOptionCol
                      type={property.component}
                      key={productOption.id}
                      productOption={productOption}
                      deleteProductOption={() =>
                        productOption.products.length ? // If there are products using this option warn first
                          setProductOptionToRemove(productOption)
                        :
                          deleteProductOption(productOption)
                            .then(() => refreshBaseProductOptions())
                      }
                      isLoading={areLoading}
                    />
                  )}
                </Row>
              </Container>
            </CardBody>
          </Card>
        </Col>
      )}
    </Row>
  </React.Fragment>
}


const OptionSelect = ({selectedOption, options: rawOptions, type, setOption, ...props}) => {
  const options = useMemo(() =>
    (rawOptions || []).map(option => ({
      value: option.id,
      label: getOptionSelectLabel(option, type),
      data: option
    }))
  , [rawOptions])

  return (
    <FormInput.Select
      {...props}
      options={options}
      value={selectedOption && options.find(({value}) => value === selectedOption.id)}
      onChange={option => setOption(option.data)}
      isSearchable
    />
  );
}


const ProductOptionCol = ({ type, productOption, deleteProductOption, isLoading }) => {

  const option = productOption?.option

  const DeleteBtn = () =>
    <Button color="danger" size="sm" onClick={deleteProductOption} disabled={isLoading}>
      <i className="fas fa-trash"/>
    </Button>

  switch(type) {
    case 'colors':
      return (
        <Col sm={6} className="d-flex align-items-center my-3">
          <div className="d-flex align-items-center flex-grow-1">
            {option?.value &&
              <div
                className="mr-2 d-inline-block"
                style={{width: 30, height: 30, backgroundColor: option?.value}}
              />}
            <h4 className="m-0">{option?.name}</h4>
          </div>
          <DeleteBtn />
        </Col>
      )
    case 'icons':
      return (
        <Col sm={6} className="d-flex align-items-center my-3">
          <div className="d-flex align-items-center flex-grow-1">
            {option?.icon &&
              <img src={option?.icon?.url} className="pr-2" style={{ height: 30 }}/>}
            <h4 className="m-0">{option?.name}</h4>
          </div>
          <DeleteBtn />
        </Col>
      )
    default:
      return (
        <Col sm={6} className="d-flex align-items-center my-3">
          <h4 className="flex-grow-1 m-0">{productOption?.option?.name}</h4>
          <DeleteBtn />
        </Col>
      )
  }
}




export default BaseProductPropertiesCards;