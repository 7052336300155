import React from "react";
import { TableOutput, Table, TableCard } from "@general-backoffice/core";
import {useNavigate} from "react-router";
import { Card } from "reactstrap";

import AddButtonChildren from "../shared/AddButtonChildren";
import validationConfig from "./shared/validationConfig"


const visibility = [
  { status: "Visible", color: "info" },
  { status: "Oculta", color: "light" }
]

const ProductsTableCard = ({ products, isLoading, pagination, goToPage, changePageSize, sort, changeSort }) => {
  const navigate = useNavigate();

  const columns = [
    {
      name: "Producto",
      formattedContent: ({ images, name }) => images?.[0] ?
        <TableOutput.ImageText src={images[0]?.thumbnail?.url || images[0].url} alt={images[0].name} text={name}/>
        :
        name,
      isTh: true,
      sort: d => changeSort('name', d),
      sortStatus: sort?.name
    },
    {
      name: "Estado",
      sort: () => null,
      formattedContent: ({ validation }) =>
        <TableOutput.Status {...(validationConfig?.[validation])}/>
    },
    {
      name: "Visibilidad",
      sort: () => null,
      formattedContent: ({ publishedAt }) =>
        <TableOutput.Status {...(publishedAt ? visibility[0] : visibility[1])}/>
    },
    {
      name: "Categoria",
      property: 'category.name',
      sort: d => changeSort('category.name', d),
      sortStatus: sort?.category?.name
    },
    {
      name: "Precio",
      formattedContent: ({ price }) => `${price.toFixed(2)} €`,
      sort: d => changeSort('price', d),
      sortStatus: sort?.price
    },
    {
      name: "Imágenes",
      sort: () => null,
      formattedContent: ({ images }) => images &&
        <TableOutput.Images images={images.map(p => ({src: p?.formats?.thumbnail?.url || p.url, alt: p.name}))}/>
    },
    {
      name: "",
      sort: () => null,
      formattedContent: product => {
        return <TableOutput.CustomActions
          actions={[
            {
              icon: <i className="fas fa-edit"/>,
              onClick: () => navigate(`${product.identifier}`),
              description: "Editar producto"
            }
          ]}
        />
      }
    }
  ]


  return (
    <Card className="overflow-hidden">
      <TableCard.Header
        title="Productos"
        buttons={[
          { children: <AddButtonChildren children="Producto"/>, onClick: () => navigate(`new`) }
        ]}
        isLoading={isLoading}
      />
      <Table
        columns={columns}
        rows={products}
        onRowClick={product => navigate(`${product.identifier}`)}
        isLoading={isLoading}
        noContentMessage="No se han encontrado productos"
        rowHeight={100}
        rowAmount={pagination?.pageSize}
      />
      <TableCard.Footer
        amount={pagination?.pageSize}
        setAmount={changePageSize}
        totalEntries={pagination?.total}
        page={pagination?.page-1}
        onChangePage={page => goToPage(page+1)}
        isLoading={isLoading}
      />
    </Card>
  );
}

export default ProductsTableCard;