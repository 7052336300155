import React, {useEffect, useMemo, useState} from "react";
import {FormInput, Table, TableCard, TableOutput} from "@general-backoffice/core/index";
import {Card} from "reactstrap";
import _ from 'lodash'

import StoreSelect from "./StoreSelect";
import {storeRequests} from "../../api/Api";


const HomeStoresTableCard = ({ stores, refreshStores, isLoading }) => {

  const [selectedStores, setSelectedStores] = useState([])
  useEffect(() => {
    let selected = (stores || []).filter(s => s.homeOrder)
    selected = selected.sort((a, b) => a.homeOrder - b.homeOrder)
    setSelectedStores(selected)
  }, [stores])

  const nonSelectedStores = useMemo(() => {
    const selectedIds = selectedStores.map(s => s.id)
    return (stores || []).filter(s => !selectedIds.includes(s.id))
  }, [selectedStores])

  const saveOrder = async () => {
    const storesToChange = []
    selectedStores.forEach((s, i) => {
      if(!s.homeOrder || s.homeOrder !== i+1)
        storesToChange.push({id: s.id, homeOrder: i+1})
    })
    nonSelectedStores.forEach(s =>
      !!s?.homeOrder &&
        storesToChange.push({id: s.id, homeOrder: null})
    )
    console.log('storesToChange', storesToChange)
    return Promise.all(storesToChange.map(({id, homeOrder}) => storeRequests.update(id, {homeOrder})))
  }


  const columns = [
    {
      name: "Tienda",
      formattedContent: ({ logo, name }) => logo ?
        <TableOutput.ImageText src={logo?.thumbnail?.url || logo.url} alt={logo.name} text={name}/>
        :
        name,
      isTh: true
    },
    {
      name: "",
      formattedContent: (store, i) => {
        return <TableOutput.CustomActions
          actions={[
            ...(!!i ? [{
              icon: <i className="fas fa-chevron-up"/>,
              disabled: true,
              onClick: () => setSelectedStores(stores => {
                const copy = _.cloneDeep(stores)
                copy.splice(i, 1)
                copy.splice(i-1, 0, store)
                return copy
              }),
              description: "Subir tienda"
            }] : []),
            ...(i < selectedStores.length-1 ? [{
              icon: <i className="fas fa-chevron-down"/>,
              onClick: () => setSelectedStores(stores => {
                const copy = _.cloneDeep(stores)
                copy.splice(i, 1)
                copy.splice(i+1, 0, store)
                return copy
              }),
              description: "Bajar tienda"
            }] : []),
            {
              icon: <i className="fas fa-times text-danger"/>,
              onClick: () => setSelectedStores(stores => {
                const copy = _.cloneDeep(stores)
                copy.splice(i, 1)
                return copy
              }),
              description: "Quitar tienda"
            }
          ]}
        />
      }
    }
  ]

  return (
    <Card className="overflow-hidden">
      <TableCard.Header
        title="Tiendas"
        buttons={[
          { children: 'Guardar', onClick: () => saveOrder().then(() => refreshStores())}
        ]}
        isLoading={isLoading}
      />

      <div className="position-absolute p-3" style={{top: 0, right: 90, maxWidth: '50%', width: 200}}>
        <StoreSelect
          className="mb--4"
          placeholder="Buscar..."
          setStore={store =>
            setSelectedStores(selectedStores.concat([store]))
          }
          stores={nonSelectedStores}
          isLoading={isLoading}
        />
      </div>

      <Table
        columns={columns}
        rows={selectedStores}
        isLoading={isLoading}
        noContentMessage="No hay tiendas listadas"
        rowHeight={100}
      />
    </Card>
  );
}



export default HomeStoresTableCard