import React from "react";
import { TableOutput, Table, TableCard } from "@general-backoffice/core";
import {useNavigate} from "react-router";
import { Card } from "reactstrap";

import AddButtonChildren from "../shared/AddButtonChildren";


const status = [
  { status: "Visible", color: "success" },
  { status: "Oculta", color: "danger" }
]

const BaseProductsTableCard = ({ baseProducts, isLoading, pagination, goToPage, changePageSize, sort, changeSort }) => {
  const navigate = useNavigate();

  const columns = [
    {
      name: "Producto base",
      formattedContent: ({ images, name }) => images?.[0] ?
        <TableOutput.ImageText src={images[0]?.thumbnail?.url || images[0].url} alt={images[0].name} text={name}/>
        :
        name,
      isTh: true,
      sort: d => changeSort('name', d),
      sortStatus: sort?.name
    },
    {
      name: "Precio base",
      formattedContent: ({ basePrice }) => `${basePrice.toFixed(2)} €`,
      isTh: true,
      sort: d => changeSort('basePrice', d),
      sortStatus: sort?.basePrice
    },
    {
      name: "Estado",
      sort: () => null,
      formattedContent: ({ publishedAt }) =>
        <TableOutput.Status {...(publishedAt ? status[0] : status[1])}/>
    },
    {
      name: "Imágenes",
      sort: () => null,
      formattedContent: ({ images }) => images &&
        <TableOutput.Images images={images.map(p => ({src: p?.formats?.thumbnail?.url || p.url, alt: p.name}))}/>
    },
    {
      name: "",
      sort: () => null,
      formattedContent: baseProduct => {
        return <TableOutput.CustomActions
          actions={[
            {
              icon: <i className="fas fa-edit"/>,
              onClick: () => navigate(`${baseProduct.id}`),
              description: "Editar producto base"
            }
          ]}
        />
      }
    }
  ]


  return (
    <Card className="overflow-hidden">
      <TableCard.Header
        title="Productos base"
        buttons={[
          { children: <AddButtonChildren children="Producto base"/>, onClick: () => navigate(`new`) }
        ]}
        isLoading={isLoading}
      />
      <Table
        columns={columns}
        rows={baseProducts}
        onRowClick={baseProduct => navigate(`${baseProduct.id}`)}
        isLoading={isLoading}
        noContentMessage="No se han encontrado productos base"
        rowHeight={100}
        rowAmount={pagination?.pageSize}
      />
      <TableCard.Footer
        amount={pagination?.pageSize}
        setAmount={changePageSize}
        totalEntries={pagination?.total}
        page={pagination?.page-1}
        onChangePage={page => goToPage(page+1)}
        isLoading={isLoading}
      />
    </Card>
  );
}

export default BaseProductsTableCard;