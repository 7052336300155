import React from "react";
import { Router as RouterCore } from "@general-backoffice/core";
import routes from "./routes";
import { useSelector } from "react-redux";
import configuration from "./config/configuration";

const { basename } = configuration

const validateRoute = (route, state) => {
  const { user, token, hotel } = state;
  const routeAccessRoles = route.accessRoles || [];

  if (route.fullPath.includes("auth") && !!token) return false;
  if (route.fullPath.includes("dashboard") && !token) return false;

  if(!routeAccessRoles?.length) return true
  else if(routeAccessRoles.includes('Hotel') && !hotel) return false
  else return routeAccessRoles.includes(user?.role)
}

const Router = () => {
  const state = useSelector((state) => state.sessionState)
  return (
    <React.Fragment>
      <RouterCore
        basename={basename}
        scrollToTop
        routes={routes(state)}
        validateRoute={(route) => validateRoute(route, state)}
      />
    </React.Fragment>
  );
}

export default Router;