import React from "react";
import { Modal, FormInput, FormDecoration } from "@general-backoffice/core";
import {Badge, Button, Col, Row} from "reactstrap";
import moment from 'moment'

import useForm from "../hooks/useForm";

import CustomInput from "../shared/CustomInput";
import statusStyles from "./utils/statusStyles";
import {TableOutput} from "@general-backoffice/core/index";


const UpdateCategoryModal = ({ sale, setSale, close, update, /*changeStatus,*/ isLoading }) => {
  const {
    id,
    createdAt,
    observations,
    price,
    // shippingPrice,
    // totalPrice,
    // user,
    // carrier,
    // code,
    store,
    shippingAddress,
    billingAddress,
    product_sales,

    status,
    trackingId
  } = sale || {}

  const {
    handleInputChange,
    handleInputEvent
  } = useForm(setSale)

  const handleSubmit = async (event) => {
    event.preventDefault()
    await update()
  }


  let buttons = []
  if(id) {
    if (['cancelled', 'inProduction'].includes(status))
      buttons.push({
        children: statusStyles.sent.status,
        color: statusStyles.sent.color,
        onClick: () => handleInputChange('status', 'sent')
      })
    if (['cancelled', 'paid', 'sent'].includes(status))
      buttons.push({
        children: statusStyles.inProduction.status,
        color: statusStyles.inProduction.color,
        onClick: () => handleInputChange('status', 'inProduction')
      })
    if (['paid', 'sent', 'inProduction'].includes(status))
      buttons.push({
        children: statusStyles.cancelled.status,
        color: statusStyles.cancelled.color,
        onClick: () => handleInputChange('status', 'cancelled')
      })
  }


  const statusStyle = statusStyles?.[status]
  const areLoading = isLoading /*|| isLoadingLang*/
  return (
    <Modal.FormContainer
      title={<>
        <Badge color="" className="badge-dot mr-4">
          <i className={`bg-${statusStyle?.color}`}/>
          <span className="text-capitalize">{statusStyle?.name || status}</span>
        </Badge>
        Detalles del pedido

        <div className="position-absolute d-flex p-4" style={{top: 0, right: 0, maxWidth: '50%'}}>
          {buttons.map(btn => <Button size="sm" {...btn}/>)}
        </div>
      </>}
      isOpen={!!sale}
      toggleOpen={close}
      closeText="Cerrar"
      buttonSave={{ children: "Guardar", disabled: areLoading }}
      size="lg"
      isLoading={areLoading}
      onSubmit={handleSubmit}
    >

      <Row>
        <Col xs={12} sm={6} md={4} lg={3}>
          <FormInput.Input
            name="trackingId"
            label="Número de seguimiento"
            value={trackingId}
            onChange={handleInputEvent}
            disabled={areLoading}
            required={['sent', 'received'].includes(status)}
          />
        </Col>

        <Col xs={12} sm={6} md={4} lg={3}>
          <CustomInput label="Fecha de pedido">
            {moment(createdAt).format('DD/MM/YYYY')}
          </CustomInput>
        </Col>
        <Col xs={12} sm={6} md={4} lg={3}>
          <CustomInput label="Tienda">
            <TableOutput.ImageText
              text={store?.name}
              src={store?.logo?.thumbnail?.url || store?.logo?.url}
              alt={store?.name}
            />
          </CustomInput>
        </Col>

      </Row>

      {product_sales?.length && <>

        <FormDecoration.Line/>

        <ul className="p-0 list-unstyled w-100">
          {(product_sales || []).map(prodSale => {
            const { id, optionsCopy, productCopy, quantity } = prodSale
            const img = productCopy?.images?.[0]
            return (
              <li key={id} className="d-flex align-items-center my-2">
                <div className="flex-grow-1">
                  <TableOutput.ImageText
                    text={<>
                      <div>
                        <b>{productCopy.name}</b> - {productCopy?.base_product?.name}
                      </div>
                      <div className="text-default" style={{fontSize: '0.9rem'}}>
                        {optionsCopy.map(o => o.name).join(', ')}
                      </div>
                    </>}
                    src={img?.thumbnail?.url || img?.url}
                    alt={img?.name}
                  />
                </div>

                <span>x{quantity}</span>

                <span style={{width: 100}} className="text-right">
                  {productCopy.price.toFixed(2)} €
                </span>
              </li>
            )
          })}
          {/*{shippingPrice &&
            <li className="flex-center justify-content-end font-weight-bold">
              <b>Envio</b>
              <span style={{width: 100}} className="text-right">
                {shippingPrice.toFixed(2)} €
              </span>
            </li>
          }*/}
          <li className="mt-3 flex-center justify-content-end font-weight-bold" style={{fontSize: '1.1rem'}}>
            <span>Total</span>
            <span style={{width: 100}} className="text-right">
              {price.toFixed(2)} €
            </span>
          </li>
        </ul>
      </>}

      <FormDecoration.Line/>

      <Row>
        <Col xs={12} sm={6}>
          <CustomInput label="Dirección de envio">
            <AddressInfo address={shippingAddress}/>
          </CustomInput>
        </Col>
        <Col xs={12} sm={6}>
          <CustomInput label="Dirección de facturación">
            <AddressInfo address={billingAddress}/>
          </CustomInput>
        </Col>
      </Row>


      {observations &&
        <CustomInput label="Observaciones">
          {observations}
        </CustomInput>
      }

    </Modal.FormContainer>
  );
}


const AddressInfo = ({address}) => {
  const {
    name,
    address1,
    address2,
    city,
    state,
    postalCode,
    country,
    email,
    phone
  } = address || {}
  return <div>
    {name && <div>Nombre: <b>{name}</b></div>}
    {address1 && <div>Linea de dirección 1: <b>{address1}</b></div>}
    {address2 && <div>Linea de dirección 2: <b>{address2}</b></div>}
    {city && <div>Población: <b>{city}</b></div>}
    {state && <div>Provincia: <b>{state}</b></div>}
    {postalCode && <div>Código postal: <b>{postalCode}</b></div>}
    {country && <div>País: <b>{country}</b></div>}
    {email && <div>Correo electronico: <b>{email}</b></div>}
    {phone && <div>Teléfono: <b>{phone}</b></div>}
  </div>
}

export default UpdateCategoryModal;