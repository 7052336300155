import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { storeRequests } from "../../../api/Api"
import useStrapi4GetList from "../../hooks/useStrapi4GetList";
import handleError from "../../utils/handleError";

// autoLoad, sort, pagination, filters, populate
const useStores = props => {
  const store = useSelector(({ sessionState }) => sessionState.store)
  const {
    items: stores,
    refresh,
    isLoading,

    ...other
  } = useStrapi4GetList({
    ...props,
    load: loadStores,
    // parseFilters
  })

  useEffect(() => {
    refresh()
    // eslint-disable-next-line
  }, [store?.id])

  return {
    stores,
    refresh,
    isLoading,

    ...other
  }
}

export const loadStores = async params => {
  try {
    return await storeRequests.getList(params)
  } catch (e) {
    handleError(e?.message || "Error al cargar tiendas")
  }
}

export default useStores;