import { notify } from "@general-backoffice/core";
import { getStrapiFileId } from "@libraries/strapi-4-utils";

import {productRequests, uploadFile} from "../../../api/Api"
import useStrapi4GetOne from "../../hooks/useStrapi4GetOne";

// import getFileId from "../../utils/getFileId";
// import { submitLocalizations } from "../../utils/localizations";
import handleError from "../../utils/handleError";


const useProduct = (id, props) => {
  const {
    item: product,
    setItem: setProduct,
    isLoading,
    setLoading,
    ...other // isLoading, setLoading, load, update, remove
  } = useStrapi4GetOne({
    id,
    ...props,
    loadItem: loadProduct,
    submitItem: submitProduct,
  })

  const changeStatus = (status, warning) => {
    if(isLoading || !product) return
    setLoading(true)
    return changeProductStatus(product.id, status, warning)
      .finally(() => {
        setLoading(false)
      })
      .catch(() => null)
  }

  const changeVisibility = show => {
    if(isLoading || !product) return
    setLoading(true)

    const publishedAt = show ? new Date().toISOString() : null
    return submitProduct({ id: product.id, publishedAt})
      .then(() =>
        setProduct(p => ({...p, publishedAt}))
      )
      .finally(() => {
        setLoading(false)
      })
      .catch(() => null)
  }

  return {
    product,
    setProduct,
    changeStatus,
    changeVisibility,
    isLoading,
    setLoading,
    ...other
  }
}


export const loadProduct = async (id, params) => {
  try {
    return parseInt(id) ?
      await productRequests.get(id, params)
      :
      await productRequests.getByIdentifier(id, params)
  } catch (e) {
    handleError(e?.message || "Error al cargar el producto")
  }
}

export const submitProduct = async product => {
  const isNew = !product?.id
  const errMsg = `${isNew ? 'Creación' : 'Modificación'} de producto fallida`
  if (!product) throw new Error(errMsg)

  try {
    const {
      id,
      images,

      baseProduct,
      category,
      store,
      product_options,
      // localizations, // name, description
      ...props // price, publishedAt, validation, warning // name, description
    } = product

    delete props.identifier

    const body = {
      base_product: baseProduct?.id || baseProduct,
      product_options: product_options && product_options.map(po => po?.id || po),
      category: category?.id || category,
      store: store?.id || store,
      images: images && await Promise.all(images.map(p => getStrapiFileId(p, uploadFile))),
      ...props
    }

    const [res] = isNew ?
      await productRequests.create(body)
      :
      await productRequests.update(id, body)

    // Update or create every localization needed
    // const defaultId = isNew ? res.id : id
    // if(localizations) await submitLocalizations(localizations, defaultId, productRequests.update, productRequests.localize)

    notify.success(`${isNew ? 'Creación' : 'Modificación'} de producto finalizada`)
    return res
  } catch (e) {
    handleError(e?.message || errMsg)
  }
}


export const changeProductStatus = async (id, status, warning) => {
  const errMsg = `Validación de producto fallida`
  if (!id || !status) throw new Error(errMsg)
  try {
    await productRequests.update(id, {
      validation: status,
      ...(status !== 'accepted' && { publishedAt: null }),
      warning: warning || null
    })
    notify.success(`Validación de producto finalizada`)
  } catch (e) {
    handleError(e?.message || errMsg)
  }
}




export default useProduct;