import _ from "lodash";

import { handleLocalizedFieldChange } from "../utils/localizations";


const useForm = (setForm, locale) => {
  if(!setForm) throw new Error('handleLocalizedInputChange requires a setter to work')

  const handleInputChange = (name, value) =>
    setForm(item => {
      const clone = _.cloneDeep(item)
      _.set(clone, name, value)
      return clone
    })


  const handleInputEvent = e => {
    const {name, value, checked, type} = e.target
    handleInputChange(name, type === "checkbox" ? checked : value)
  }

  const handleLocalizedInputChange = (name, value) => {
    if(!locale) throw new Error('handleLocalizedInputChange requires a locale to be set')
    handleLocalizedFieldChange(locale, name, value, setForm)
  }

  const handleLocalizedInputEvent = e => {
    const { name, value, checked, type } = e.target
    handleLocalizedFieldChange(locale, name, type === "checkbox" ? checked : value, setForm)
  }

  return {
    handleInputChange,
    handleInputEvent,
    handleLocalizedInputChange,
    handleLocalizedInputEvent
  }
}

export default useForm;