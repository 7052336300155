import React, { useState } from "react";
import {Card, CardBody, Form, Row, Col, Button} from "reactstrap";
import CardHeader from "@general-backoffice/core/lib/components/card/CardHeader";
import { Modal, FormInput, FormDecoration } from "@general-backoffice/core/index";
import { useFileInput } from "@libraries/strapi-4-utils"
import _ from 'lodash'

// import useLang from "../hooks/useLang";
import useForm from "../hooks/useForm";

import AddButtonChildren from "../shared/AddButtonChildren";
import CustomInput from "../shared/CustomInput";



const StoreDetailsCard = ({ store, setStore, updateStore, isLoading }) => {
  const {
    id,
    email,
    social_networks,

    publishedAt,
    // finishedStripeOnboarding,

    logo,
    banner,
    image,

    favicon,
    description,

    name,
    aboutUs,
    // refunds,
    // shippingDetails,

    managerEmail

    // locale,
    // localizations
  } = store || {}

  const { file: logoFile, isLoading: isLoadingLogoFile } = useFileInput(logo)
  const { file: bannerFile, isLoading: isLoadingBannerFile } = useFileInput(banner)
  const { file: imageFile, isLoading: isLoadingImageFile } = useFileInput(image)
  const { file: faviconFile, isLoading: isLoadingFaviconFile } = useFileInput(favicon)

  // const {
  //   lang,
  //   LangSelector,
  //   isLoading: isLoadingLang
  // } = useLang({
  //   loaded: !!store,
  //   locale,
  //   setLocale: locale => handleInputChange('locale', locale)
  // })
  // const { aboutUs, shippingDetails, refunds,  } = localizations?.[lang] || {}

  const {
    handleInputEvent,
    handleInputChange,
    // handleLocalizedInputEvent
  } = useForm(setStore, /*lang*/)


  const [newSocialNetwork, setNewSocialNetwork] = useState()

  const areLoading = isLoading
  return (<>

    <AddStoreSocialNetwork
      socialNetworkToCreate={newSocialNetwork}
      setSocialNetworkToCreate={setNewSocialNetwork}
      close={() => setNewSocialNetwork(null)}
      create={() => {
        handleInputChange('social_networks', (social_networks || []).concat([newSocialNetwork]))
        setNewSocialNetwork(null)
      }}
      isLoading={isLoading}
    />

    <Card className="overflow-hidden">
      <CardHeader
        title={id ? name : 'Nueva tienda'}
        subtitle={store?.name}
        isLoading={areLoading}
        buttons={[{
          children: "Guardar",
          disabled: areLoading,
          form: "storeDetails",
          type: 'submit'
        }]}
      />
      <CardBody>
        <Form
          id="storeDetails"
          onSubmit={e => {
            e.preventDefault()
            updateStore()
          }}
        >

          {!id && <>
            <Row className="flex-center">
              <Col xs={12} md={6} lg={4}>
                <FormInput.Input
                  type="email"
                  name="managerEmail"
                  label="Correo electronico del administrador"
                  value={managerEmail}
                  onChange={handleInputEvent}
                  disabled={areLoading}
                  required
                />
              </Col>
            </Row>
            <FormDecoration.Line/>
          </>}

          <Row>
            <Col>
              <FormInput.Input
                name="name"
                label="Nombre"
                value={name}
                onChange={handleInputEvent}
                disabled={areLoading}
                required
              />
              {id &&
                // <FormInput.Switch
                //   onChange={({ target: { checked } }) => handleInputChange("publishedAt", checked ? new Date().toISOString() : null)}
                //   checked={finishedStripeOnboarding && !!publishedAt}
                //   name="publishedAt"
                //   label="Visible"
                //   labels={["Yes", "No"]}
                //   error={!finishedStripeOnboarding && 'Onboarding necesario'}
                //   disabled={areLoading || !finishedStripeOnboarding}
                // />
                <FormInput.Switch
                  onChange={({ target: { checked } }) => handleInputChange("publishedAt", checked ? new Date().toISOString() : null)}
                  checked={!!publishedAt}
                  name="publishedAt"
                  label="Visible"
                  labels={["Yes", "No"]}
                  disabled={areLoading}
                />
              }
              <FormInput.Input
                name="email"
                label="Correo"
                type="email"
                value={email}
                onChange={handleInputEvent}
                disabled={areLoading}
                required
              />
            </Col>
            <Col xs={12} md={6}>
              <FormInput.SingleDropzone
                label="Logo"
                accept="image/*"
                file={logoFile}
                onDrop={(file) => handleInputChange('logo', file)}
                disabled={areLoading || isLoadingLogoFile}
                required
              />
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={6}>
              <FormInput.SingleDropzone
                label="Imagen"
                accept="image/*"
                file={imageFile}
                onDrop={(file) => handleInputChange('image', file)}
                disabled={areLoading || isLoadingImageFile}
                required
              />
            </Col>
            <Col xs={12} md={6}>
              <FormInput.SingleDropzone
                label="Cabecera"
                accept="image/*"
                file={bannerFile}
                onDrop={(file) => handleInputChange('banner', file)}
                disabled={areLoading || isLoadingBannerFile}
                required
              />
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={6}>
              <CustomInput
                label="Redes sociales"
                actions={
                  <Button size="sm" color="primary" onClick={() => setNewSocialNetwork({})}>
                    <AddButtonChildren>Add</AddButtonChildren>
                  </Button>
                }
              >
                {social_networks?.length ? social_networks.map(({ name, url }, i) =>
                    <div key={i} className="d-flex justify-content-between pl-2 py-2">
                      <a href={'//'+url} target="_blank" rel="noreferrer" className="text-capitalize font-weight-bold text-underline">
                        {name}
                      </a>
                      <Button
                        size="sm" color="danger"
                        onClick={() => {
                          const snCopy = _.cloneDeep(social_networks)
                          snCopy.splice(i, 1)
                          handleInputChange('social_networks', snCopy)
                        }}
                      ><i className="fas fa-trash"/></Button>
                    </div>
                  )
                :
                  <div className="text-center mx-5">Sin redes sociales</div>
                }
              </CustomInput>
            </Col>
          </Row>

          <FormDecoration.LineTitle>SEO</FormDecoration.LineTitle>

          <Row>
            <Col xs={12} md={6}>
              <FormInput.SingleDropzone
                label="Favicon"
                accept=".ico"
                file={faviconFile}
                onDrop={(file) => handleInputChange('favicon', file)}
                disabled={areLoading || isLoadingFaviconFile}
              />
            </Col>
            <Col>
              <FormInput.Input
                type="textarea"
                rows="4"
                name="description"
                label="Descripción"
                value={description}
                onChange={handleInputEvent}
                disabled={areLoading}
              />
            </Col>
          </Row>

          <FormDecoration.Line/>

          <FormInput.Quill
            label="Nosotros"
            value={aboutUs || ""}
            onChange={value => handleInputChange('aboutUs', value)}
            readOnly={areLoading}
          />

        </Form>
      </CardBody>
    </Card>
  </>)
}


const socialNetworks = ['instagram', 'twitter', 'pinterest', 'facebook', 'web', 'other'].map(sn => ({
  value: sn,
  label: sn.charAt(0).toUpperCase() + sn.slice(1)
}))

const AddStoreSocialNetwork = ({ socialNetworkToCreate, setSocialNetworkToCreate, close, create, isLoading }) => {
  const { name, url } = socialNetworkToCreate || {}
  const {
    handleInputEvent,
    handleInputChange
  } = useForm(setSocialNetworkToCreate)

  const areLoading = isLoading
  return (
    <Modal.FormContainer
      title="Add social network"
      isOpen={!!socialNetworkToCreate}
      toggleOpen={close}
      closeText="Close"
      buttonSave={{ children: "Save", disabled: areLoading }}
      isLoading={areLoading}
      onSubmit={create}
    >
      <FormInput.Input
        onChange={handleInputEvent}
        type="url"
        value={url}
        name="url"
        label="Url"
        disabled={areLoading}
      />
      <FormInput.Select
        label="Red social"
        onChange={({value}) => handleInputChange('name', value || null)}
        options={socialNetworks}
        value={name && socialNetworks.find(({value}) => value === name)}
        isDisabled={areLoading}
        required
      />
    </Modal.FormContainer>
  );
}


export default StoreDetailsCard;