import { notify } from "@general-backoffice/core";

import { productRequests } from "../../../api/Api"
import useStrapi4GetList from "../../hooks/useStrapi4GetList";
import handleError from "../../utils/handleError";

// autoLoad, sort, pagination, filters, populate
const useProducts = props => {
  const {
    items: products,
    ...other
  } = useStrapi4GetList({
    ...props,
    load: loadProducts,
    // parseFilters
  })

  return {
    products,
    ...other
  }
}

export const loadProducts = async params => {
  try {
    return await productRequests.getList(params)
  } catch (e) {
    handleError(e?.message || "Error al cargar productos")
  }
}

export default useProducts;