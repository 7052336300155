import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { Header } from "@general-backoffice/core/index";
import { useNavigate } from "react-router";

import {categoryRequests} from "../../../../api/Api";

import useCategories from "../../../../components/categories/hooks/useCategories";
import useCategory from "../../../../components/categories/hooks/useCategory";

// Components
import CategoriesTableCard from "../../../../components/categories/CategoriesTableCard"
import UpdateCategoryModal from "../../../../components/categories/UpdateCategoryModal"
import DeleteCategoryModal from "../../../../components/categories/DeleteCategoryModal"


const Categories = ({ store }) => {
  const navigate = useNavigate()
  const {
    categories,
    isLoading: isLoadingCategories,
    refresh: refreshCategories,
    goToPage,
    changePageSize,
    pagination,
    sort,
    changeSort
  } = useCategories({
    populate: categoryRequests.defaultPopulate.concat(['products']),
    filters: { 'filters[store]': store },
    sort: true,
    pagination: true,
    onLoadError: () => navigate('/dashboard', { replace: true }),
    depsToReset: [store]
  })

  const [modal, setModal] = useState()
  const {
    category,
    setCategory,
    update: updateCategory,
    remove: deleteAdvantage,
    isLoading: isHandlingCategory
  } = useCategory()

  const areLoading = isLoadingCategories || isHandlingCategory
  return (
    <React.Fragment>

      <UpdateCategoryModal
        category={modal === 'update' && category}
        setCategory={setCategory}
        close={() => setCategory(null)}
        update={() => {
          return updateCategory()
            .then(async () => {
              await refreshCategories()
              setCategory(null)
            })
            .catch(() => null)
        }}
        isLoading={areLoading}
      />

      <DeleteCategoryModal
        category={modal === 'delete' && category}
        close={() => setCategory(null)}
        remove={() => {
          deleteAdvantage()
            .then(async () => {
              await refreshCategories()
              setCategory(null)
            })
            .catch(() => null)
        }}
        isLoading={areLoading}
      />

      <Header.BreadCrumb color="secondary" isDark={false}/>
      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <CategoriesTableCard
              {...{
                goToPage,
                changePageSize,
                sort,
                changeSort,
                pagination
              }}
              categories={categories}
              defaultCategory={{ store }}
              setCategoryToUpdate={c => {
                setCategory(c)
                setModal('update')
              }}
              setCategoryToDelete={c => {
                setCategory(c)
                setModal('delete')
              }}
              isLoading={areLoading}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default Categories;