import { notify } from "@general-backoffice/core";

import { baseProductRequests } from "../../../api/Api"
import useStrapi4GetList from "../../hooks/useStrapi4GetList";
import handleError from "../../utils/handleError";

// autoLoad, sort, pagination, filters, populate
const useBaseProducts = props => {
  const {
    items: baseProducts,
    ...other
  } = useStrapi4GetList({
    ...props,
    load: loadBaseProducts,
    // parseFilters
  })

  return {
    baseProducts,
    ...other
  }
}

export const loadBaseProducts = async params => {
  try {
    return await baseProductRequests.getList(params)
  } catch (e) {
    handleError(e?.message || "Error al cargar productos base")
  }
}

export default useBaseProducts;