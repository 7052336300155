import React from "react";
import { TableOutput, Table, TableCard, FormInput } from "@general-backoffice/core";
import {Badge, Card} from "reactstrap";
import moment from 'moment'
import _ from 'lodash'

import statusStyles from "./utils/statusStyles";


const SalesTableCard = ({ sales, isLoading, setSaleToUpdate, filters, changeFilters, pagination, goToPage, changePageSize, sort, changeSort }) => {

  const columns = [
    {
      name: "Cliente",
      formattedContent: ({ shippingAddress }) => <div>
        <div>{shippingAddress?.name}</div>
        <div className="font-weight-normal">
          <div>{shippingAddress?.email}</div>
          <div>{shippingAddress?.phone}</div>
        </div>
      </div>,
      isTh: true
    },

    ...(!filters?.['filters[store]'] ? [{
      name: "Tienda",
      formattedContent: ({ store }) => store &&
        <TableOutput.ImageText
          text={store?.name}
          src={store?.logo?.thumbnail?.url || store?.logo?.url}
          alt={store?.name}
        />
    }] : []),

    {
      name: "Productos",
      formattedContent: ({ product_sales }) =>
        <TableOutput.Images
          images={product_sales
            .filter(({product}) => !!product?.images?.length)
            .map(({product}) => ({
              src: product.images[0]?.thumbnail?.url || product.images[0].url,
              alt: product.images[0].name
            }))
          }
        />
    },
    {
      name: "Precio",
      formattedContent: ({ price }) => `${price.toFixed(2)} €`,
      sort: d => changeSort('price', d),
      sortStatus: sort?.price
    },
    {
      name: "Estado",
      formattedContent: ({ status }) =>
        <TableOutput.Status {...statusStyles[status]}/>
    },
    {
      name: "Fecha",
      formattedContent: ({ createdAt }) =>
        moment(createdAt).format('DD/MM/YYYY'),
      sort: d => changeSort('createdAt', d),
      sortStatus: sort?.createdAt
    },
    {
      formattedContent: sale => {
        return <TableOutput.CustomActions
          actions={[
            {
              icon: <i className="fas fa-edit"/>,
              onClick: () => setSaleToUpdate(_.cloneDeep(sale)),
              description: "Ver producto base"
            }
          ]}
        />
      }
    }
  ]


  return (
    <Card className="position-relative">
      <TableCard.Header
        title="Ventas"
        isLoading={isLoading}
      />

      <div className="position-absolute p-3" style={{top: 0, right: 0, maxWidth: '50%', minWidth: 200}}>
        <StatusSelect
          className="mb--4"
          placeholder="Mostrar todas"
          selectedStatus={filters?.['filters[status]']}
          setStatus={status => changeFilters('filters[status]', status)}
          isSearchable={false}
          isClearable
        />
      </div>

      <Table
        columns={columns}
        rows={sales}
        onRowClick={sale => setSaleToUpdate(_.cloneDeep(sale))}
        isLoading={isLoading}
        noContentMessage="No se han encontrado ventas"
        rowHeight={100}
        rowAmount={pagination?.pageSize}
      />
      <TableCard.Footer
        amount={pagination?.pageSize}
        setAmount={changePageSize}
        totalEntries={pagination?.total}
        page={pagination?.page-1}
        onChangePage={page => goToPage(page+1)}
        isLoading={isLoading}
      />
    </Card>
  );
}


const StatusSelect = ({selectedStatus, setStatus, ...props}) => {
  const options = Object.entries(statusStyles).map(([value, {status: name, color}]) => ({
    value,
    label: <Badge color="" className="badge-dot mr-4">
      <i className={`bg-${color}`}/>
      <span className="text-capitalize">{name || value}</span>
    </Badge>
  }))

  return (
    <FormInput.Select
      {...props}
      options={options}
      value={selectedStatus && options.find(({value}) => value === selectedStatus)}
      onChange={option => setStatus(option?.value)}
    />
  );
}


export default SalesTableCard;