import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Header } from "@general-backoffice/core/index";
import { useNavigate } from "react-router";

import useBaseProducts from "../../../../components/baseProducts/hooks/useBaseProducts";

// Components
import BaseProductsTableCard from "../../../../components/baseProducts/BaseProductsTableCard"


const BaseProducts = () => {
  const navigate = useNavigate()
  const {
    baseProducts,
    isLoading: isLoadingBaseProducts,
    goToPage,
    changePageSize,
    pagination,
    sort,
    changeSort
  } = useBaseProducts({
    sort: true,
    pagination: true,
    onLoadError: (e) => navigate('/dashboard', { replace: true })
  })


  return (
    <React.Fragment>
      <Header.BreadCrumb color="secondary" isDark={false}/>
      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <BaseProductsTableCard
              {...{
                goToPage,
                changePageSize,
                sort,
                changeSort,
                pagination
              }}
              baseProducts={baseProducts}
              isLoading={isLoadingBaseProducts}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default BaseProducts;