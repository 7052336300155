import React from "react";
import { Modal } from "@general-backoffice/core";


const DeleteProductOptionModal = ({ productOption, close, remove, isLoading }) => {
  return (
    <Modal.DeleteConfirm
      isOpen={!!productOption}
      isLoading={isLoading}
      toggleOpen={close}
      titleDelete="Quitar opción"
      descriptionDelete={<>
        <div>Estás a punto de quitar la opción <span className="font-weight-bold">"{productOption?.option?.name}"</span> del producto base.</div>
        <div>La opción también será eliminada de los productos:</div>
        <ul className="text-left">
          {(productOption?.products || []).map(product =>
            <li><span className="font-weight-bold ml-2">{product.name}</span> de {product.store.name}</li>
          )}
        </ul>
      </>}
      closeText="Cancelar"
      buttonDelete={{ children: "Eliminar", onClick: remove }}
    />
  );
}

export default DeleteProductOptionModal;