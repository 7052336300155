import { FormGroup } from "reactstrap";

const CustomInput = ({ label, actions, children }) =>
  <FormGroup>
    <div className="d-flex justify-content-between mb-2">
      <label className="form-control-label mb-0">{label}</label>
      {actions}
    </div>
    {children}
  </FormGroup>

export default CustomInput
