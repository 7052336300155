import { RestApi } from "@general-backoffice/core";
import configuration from "./../config/configuration"
import parseStrapi4Response from "./utils/parseStrapi4Response";

const { apiUrl, mediaUrl } = configuration;

const Api = new RestApi({
  path: apiUrl,
  processResponse: ({ data }) => parseStrapi4Response(data, mediaUrl),
  processError: e => {throw new Error(e.response?.data?.error?.message || e.message)}
})



// const getBaseProduct = (id, params) => Api.get(`/base-products/${id}`, { params: { populate: populateBaseProduct, ...params } });
// const getBaseProducts = params => Api.get(`/base-products`, { params: { populate: populateBaseProduct, publicationState: 'preview', ...params } });
//
const getByIdentifier = (identifier, params, getList) =>
  getList({ ...params, 'filters[identifier]': identifier })
    .then(([items]) => {
      const item = items[0]
      if(!item) throw new Error("Not found")
      return [item]
    })
//
//
//   await productRequests.getList({ ...params, 'filters[identifier]': id }).then((res) => {
//     console.log('tre', res)
//     return {}
//     // return res?.[0]
//   })




//  User Requests
export const login = async (identifier, password) => Api.post("/auth/local?[populate]=*", { identifier, password })
// export const me = async () => Api.get(`/users/me?[populate]=*`);
export const uploadFile = async file => {
  const form = new FormData()
  form.append('files', file)
  return Api.post(`/upload`, form);
}
export const getLocales = async () => Api.get(`/i18n/locales`);

const confirmRemoval = token => Api.post("/users/me/confirm-remove", { token })
const forgotPassword = email => Api.post(`/auth/forgot-password`, { email })
const resetPassword = (code, password, passwordConfirmation) => Api.post(`/auth/reset-password`, { code, password, passwordConfirmation })



//  User Requests
const getUser = (id, params) => Api.get(`/users/${id}`, { params });
// const getUsersByRole = (role, params) => Api.get(`/users/role/${role}`, { params });
const getUsers = params => Api.get(`/users`, { params });
const updateUser = (id, body) => Api.put(`/users/${id}`, {data: body});

const userRequests = {
  get: getUser,
  // getListByRole: getUsersByRole,
  getList: getUsers,
  update: updateUser,
}


//  Sale Requests
const populateStore = ['localizations', 'logo', 'banner', 'image', 'favicon', 'domains', 'social_networks']
const getStore = (id, params) => Api.get(`/stores/${id}`, { params: { populate: populateStore, ...params } });
const getStoreByIdentifier = (identifier, params) => getByIdentifier(identifier, params, getStores)
const getStores = params => Api.get(`/stores`, { params: { populate: populateStore, publicationState: 'preview', ...params } });
const createStore = body => Api.post(`/stores`, {data: body});
const updateStore = (id, body) => Api.put(`/stores/${id}`, {data: body});
const localizeStore = (id, body) => Api.post(`/stores/${id}/localizations`, body);
const getOnboardingLink = (id, returnUrl) => Api.get(`/stores/${id}/onboarding`, { params: {returnUrl} });
const checkOnboardingStatus = id => Api.get(`/stores/${id}/onboardingStatus`);

const storeRequests = {
  defaultPopulate: populateStore,
  get: getStore,
  getByIdentifier: getStoreByIdentifier,
  getList: getStores,
  create: createStore,
  update: updateStore,
  localize: localizeStore,
  getOnboardingLink,
  checkOnboardingStatus
}


//  Category Requests
const populateCategory = ['localizations', 'image', 'banner']
const getCategory = (id, params) => Api.get(`/categories/${id}`, { params: { populate: populateCategory, ...params } });
const getCategoryByIdentifier = (identifier, params) => getByIdentifier(identifier, params, getCategories)
const getCategories = params => Api.get(`/categories`, { params: { populate: populateCategory, ...params } });
const createCategory = body => Api.post(`/categories`, {data: body});
const updateCategory = (id, body) => Api.put(`/categories/${id}`, {data: body});
const localizeCategory = (id, body) => Api.post(`/categories/${id}/localizations`, body);
const deleteCategory = id => Api.delete(`/categories/${id}`);

const categoryRequests = {
  defaultPopulate: populateCategory,
  get: getCategory,
  getByIdentifier: getCategoryByIdentifier,
  getList: getCategories,
  create: createCategory,
  update: updateCategory,
  localize: localizeCategory,
  delete: deleteCategory
}


//  Sale Requests
const populateBaseProduct = ['localizations', 'images']
const getBaseProduct = (id, params) => Api.get(`/base-products/${id}`, { params: { populate: populateBaseProduct, ...params } });
const getBaseProducts = params => Api.get(`/base-products`, { params: { populate: populateBaseProduct, publicationState: 'preview', ...params } });
const createBaseProduct = body => Api.post(`/base-products`, {data: body});
const updateBaseProduct = (id, body) => Api.put(`/base-products/${id}`, {data: body});
const localizeBaseProduct = (id, body) => Api.post(`/base-products/${id}/localizations`, body);

const baseProductRequests = {
  defaultPopulate: populateBaseProduct,
  get: getBaseProduct,
  getList: getBaseProducts,
  create: createBaseProduct,
  update: updateBaseProduct,
  localize: localizeBaseProduct
}


//  Product Requests
const populateProduct = ['localizations', 'images', 'category', 'product_options.option', 'base_product']
const getProduct = (id, params) => Api.get(`/products/${id}`, { params: { populate: populateProduct, ...params } });
const getProductByIdentifier = (identifier, params) => getByIdentifier(identifier, params, getProducts)
const getProducts = params => Api.get(`/products`, { params: { populate: populateProduct, publicationState: 'preview', ...params } });
const createProduct = body => Api.post(`/products`, {data: body});
const updateProduct = (id, body) => Api.put(`/products/${id}`, {data: body});
const localizeProduct = (id, body) => Api.post(`/products/${id}/localizations`, body);

const productRequests = {
  defaultPopulate: populateProduct,
  get: getProduct,
  getByIdentifier: getProductByIdentifier,
  getList: getProducts,
  create: createProduct,
  update: updateProduct,
  localize: localizeProduct
}


//  Property Requests
const populateProperty = ['localizations']
const getProperty = (id, params) => Api.get(`/properties/${id}`, { params: { populate: populateProperty, ...params } });
const getProperties = params => Api.get(`/properties`, { params: { populate: populateProperty, ...params } });

const propertyRequests = {
  defaultPopulate: populateProperty,
  get: getProperty,
  getList: getProperties
}


//  Option Requests
const populateOption = ['localizations', 'property', 'icon ']
const getOption = (id, params) => Api.get(`/options/${id}`, { params: { populate: populateProductOption, ...params } });
const getOptions = params => Api.get(`/options`, { params: { populate: populateProductOption, ...params } });
const createOption = body => Api.post(`/options`, {data: body});
const updateOption = (id, body) => Api.put(`/options/${id}`, {data: body});
const localizeOption = (id, body) => Api.post(`/options/${id}/localizations`, body);

const optionRequests = {
  defaultPopulate: populateOption,
  get: getOption,
  getList: getOptions,
  create: createOption,
  update: updateOption,
  localize: localizeOption
}


//  ProductOption Requests
const populateProductOption = ['option.property', 'option.icon']
const getProductOption = (id, params) => Api.get(`/product-options/${id}`, { params: { populate: populateProductOption, ...params } });
const getProductOptions = params => Api.get(`/product-options`, { params: { populate: populateProductOption, ...params } });
const createProductOption = body => Api.post(`/product-options`, {data: body});
const updateProductOption = (id, body) => Api.put(`/product-options/${id}`, {data: body});
const deleteProductOption = id => Api.delete(`/product-options/${id}`);

const productOptionRequests = {
  defaultPopulate: populateProductOption,
  get: getProductOption,
  getList: getProductOptions,
  create: createProductOption,
  update: updateProductOption,
  delete: deleteProductOption
}


//  Sale Requests
const populateSale = ['user', 'store.logo', 'product_sales.product.images', 'carrier']
const getSale = (id, params) => Api.get(`/sales/${id}`, { params: { populate: populateSale, ...params } });
const getSales = params => Api.get(`/sales`, { params: { populate: populateSale, ...params } });
const createSale = body => Api.post(`/sales`, {data: body});
const updateSale = (id, body) => Api.put(`/sales/${id}`, {data: body});

const saleRequests = {
  defaultPopulate: populateSale,
  get: getSale,
  getList: getSales,
  create: createSale,
  update: updateSale
}



export {
  forgotPassword,
  resetPassword,
  confirmRemoval,
  userRequests,
  storeRequests,
  categoryRequests,
  baseProductRequests,
  productRequests,
  propertyRequests,
  optionRequests,
  productOptionRequests,
  saleRequests
}
export default Api;