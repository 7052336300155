import { categoryRequests } from "../../../api/Api"
import useStrapi4GetList from "../../hooks/useStrapi4GetList";
import handleError from "../../utils/handleError";

const useCategories = props => {
  const {
    items: categories,
    refresh,
    isLoading,

    ...other
  } = useStrapi4GetList({
    ...props,
    load: loadCategories,
    // parseFilters
  })

  return {
    categories,
    refresh,
    isLoading,

    ...other
  }
}

export const loadCategories = async params => {
  try {
    return await categoryRequests.getList(params)
  } catch (e) {
    handleError(e?.message || "Error al cargar categorias")
  }
}

export default useCategories;