import { notify } from "@general-backoffice/core";

import { productOptionRequests } from "../../../api/Api"
import useStrapi4GetOne from "../../hooks/useStrapi4GetOne";

import getFileId from "../../utils/getFileId";
import handleError from "../../utils/handleError";


const useProductOption = (id, props) => {
  const {
    item: productOption,
    setItem: setProductOption,
    isLoading,
    setLoading,
    ...other // isLoading, setLoading, load, update, remove
  } = useStrapi4GetOne({
    id,
    ...props,
    loadItem: loadProductOption,
    submitItem: submitProductOption,
    deleteItem: deleteProductOption
  })


  return {
    productOption,
    setProductOption,
    isLoading,
    setLoading,
    ...other
  }
}


export const loadProductOption = async (id, params) => {
  try {
    return parseInt(id) ?
      await productOptionRequests.get(id, params)
      :
      await productOptionRequests.getByIdentifier(id, params)
  } catch (e) {
    handleError(e?.message || "Error al cargar opción de producto")
  }
}

export const submitProductOption = async productOption => {
  const isNew = !productOption?.id
  const errMsg = `${isNew ? 'Creación' : 'Modificación'} de opción de producto fallida`
  if (!productOption) throw new Error(errMsg)

  try {
    const {
      id,
      option,
      base_product,
      ...props
    } = productOption

    delete props.products

    const body = {
      ...(isNew && {
        option: option?.id || option,
        base_product: base_product?.id || base_product,
      }),
      ...props
    }

    const [res] = isNew ?
      await productOptionRequests.create(body)
      :
      await productOptionRequests.update(id, body)

    notify.success(`${isNew ? 'Creación' : 'Modificación'} de opción de producto finalizada`)
    return res
  } catch (e) {
    handleError(e?.message || errMsg)
  }
}


export const deleteProductOption = async productOption => {
  const errMsg = "Error al intentar eliminar opción de producto"
  if (!productOption?.id) throw new Error(errMsg)
  try {
    await productOptionRequests.delete(productOption.id)
    notify.success("Eliminación de Opción de producto finalizada correctamente")
  } catch (e) {
    handleError(e?.message || errMsg)
  }
}


export default useProductOption;