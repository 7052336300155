import {
  LOGIN,
  LOGOUT,
  CHANGE_STORE
} from "./actions";
import Api from "../../../api/Api";

const initialState = {
  token: null,
  user: {
    id: null,
    email: ""
  },
  store: null,
  stores: [],
};

const state = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN: {
      Api.token = action.payload.token;
      Api.params = {}
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token
      };
    }
    case LOGOUT: {
      Api.token = null;
      Api.params = {};
      return initialState;
    }
    case CHANGE_STORE: {
      const { store, stores } = action.payload
      return {
        ...state,
        ...(store && {store}),
        ...(stores && {stores})
      };
    }
    default: {
      return state;
    }
  }
};

export default state;
