import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { Header } from "@general-backoffice/core/index";
import { useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";

// Hooks
import useBaseProduct from "../../../../components/baseProducts/hooks/useBaseProduct";

// Components
import BaseProductDetailsCard from "../../../../components/baseProducts/BaseProductDetailsCard";
import BaseProductPropertiesCards from "../../../../components/baseProducts/BaseProductPropertiesCards";


const defaultStore = {
  publishedAt: null,
}

const BaseProduct = props => {
  const navigate = useNavigate()
  const store = useSelector(({ sessionState }) => sessionState.store)

  const { id = props?.id } = useParams()
  const {
    baseProduct,
    setBaseProduct,
    load: reload,
    update: updateBaseProduct,
    isLoading
  } = useBaseProduct(id, {
    onLoadError: () => navigate(-1, { replace: true })
  })

  useEffect(() => {
    if(!id) setBaseProduct({...defaultStore, store})
    // eslint-disable-next-line
  }, [id])

  return (
    <React.Fragment>
      <Header.BreadCrumb color="secondary" isDark={false}/>
      <Container className="mt--6" fluid>
        <Row>
          <Col xs={12}>
            <BaseProductDetailsCard
              baseProduct={baseProduct}
              setBaseProduct={setBaseProduct}
              updateBaseProduct={() => updateBaseProduct()
                .then(newBaseProduct => {
                  if(!baseProduct?.id) navigate(`/dashboard/base-products/${newBaseProduct.id}`, { replace: true })
                })
              }
              isLoading={isLoading}
            />
          </Col>
          {id &&
            <Col xs={12}>
              <BaseProductPropertiesCards
                baseProduct={baseProduct}
                isLoading={isLoading}
              />
            </Col>
          }
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default BaseProduct;