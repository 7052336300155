import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { Header } from "@general-backoffice/core/index";
import { useNavigate, useParams } from "react-router";
import { notify } from "@general-backoffice/core";
import { useDispatch, useSelector } from "react-redux";
import { setStore as setSessionStore } from "../../../../redux/reducers/session/actions";

import useStore from "../../../../components/stores/hooks/useStore";

// Components
import StoreDetailsCard from "../../../../components/stores/StoreDetailsCard";

const defaultStore = {
  publishedAt: null,
}

const Store = props => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const sessionStore = useSelector(({ sessionState }) => sessionState.store)

  const { identifier = props?.identifier } = useParams()
  const {
    store,
    setStore,
    load: loadStore,
    update: updateStore,
    isLoading
  } = useStore(identifier, {
    onLoadError: () => navigate(-1, { replace: true })
  })

  useEffect(() => {
    if(!identifier) setStore({...defaultStore})
    // eslint-disable-next-line
  }, [identifier])

  useEffect(() => {
    if(
      !!identifier && !!store &&
      (!sessionStore || `${sessionStore?.identifier}` !== `${store?.identifier}`)
    ) {
      dispatch(setSessionStore(store))
      notify(`Welcome to ${store?.name}`)
    }
  }, [store])

  return (
    <React.Fragment>
      <Header.BreadCrumb color="secondary" isDark={false}/>
      <Container className="mt--6" fluid>
        <Row>
          <Col xs={12}>
            <StoreDetailsCard
              store={store}
              setStore={setStore}
              updateStore={() => updateStore()
                .then(newStore => {
                  if(!store?.id) navigate(`/dashboard/stores/${newStore.identifier}`, { replace: true })
                  else loadStore()
                })
              }
              isLoading={isLoading}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default Store;