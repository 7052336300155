import React from "react";
import { notify, Page } from "@general-backoffice/core";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { storeRequests, login as apiLogin } from "../../../api/Api";
import { login, setStore, logout } from "../../../redux/reducers/session/actions"

const Login = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const onSubmit = async ({ email, password }) => {
    try {
      const { jwt, user } = await apiLogin(email, password)
      const { managedStore, role } = user

      dispatch(login(jwt, {...user, role: role.name}))

      switch(role.name) {
        case 'Admin': {
          const [stores] = await storeRequests.getList()
          dispatch(setStore(stores?.[0], stores))
          break;
        }
        case 'Store': {
          if(!managedStore) return dispatch(logout())
          const [store] = await storeRequests.get(managedStore.id)
          if(store) dispatch(setStore(store, [store]));
          else dispatch(logout())
          break;
        }
        default:
          throw new Error('No tienes el permiso necesario')
      }

      notify.success("Bienvenido!", { icon: <i className="fas fa-handshake ni-lg"/> })
    } catch (e) {
      notify.error(e.message)
      dispatch(logout())
    }
  }

  return <Page.Login
    emailPlaceholder="Email"
    forgottenPasswordText="Contraseña olvidada?"
    invalidEmailFeedbackText="Introduce un email válido"
    invalidPasswordFeedbackText="Introduce una contraseña válida"
    lead="Por favor, inicia sesión para acceder al panel de control"
    onClickForgottenPassword={() => navigate("/auth/forgotten-password")}
    onClickRegister={() => navigate("/auth/register")}
    onSubmit={onSubmit}
    passwordPlaceholder="Contraseña"
    registerText=""
    rememberLabel="Recuerdame"
    submitText="Inicia sesión"
    title="Bienvenido!"
  />;
}

export default Login;