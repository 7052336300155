import React from "react";

const AddButtonChildren = ({ children }) => {
  return (
    <div className="flex-content-center">
      <i className="fas fa-plus mr-2"/>
      {children}
    </div>
  );
}

export default AddButtonChildren;