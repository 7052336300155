
import { Navigate } from "react-router";
import Stores from "../../views/pages/dashboard/stores/Stores"
import Store from "../../views/pages/dashboard/stores/Store";


const subRoutes = state => [
  {
    name: "Tiendas",
    path: "",
    component: <Stores />
  },
  {
    name: "Tienda",
    path: ":identifier",
    component: <Store />
  },
  {
    name: "Crear tienda",
    path: "new",
    component: <Store />
  },
  {
    path: "*",
    index: true,
    component: <Navigate to="/dashboard/stores" replace={true}/>,
  }
]

export default subRoutes;
