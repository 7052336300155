import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { Header } from "@general-backoffice/core/index";
// import { useNavigate } from "react-router";

// import {categoryRequests} from "../../../../api/Api";

import useStores from "../../../../components/stores/hooks/useStores";

// Components
import HomeStoresTableCard from "../../../../components/stores/HomeStoresTableCard"


const Home = () => {
  const {
    stores,
    refresh,
    isLoading: isLoadingStores
  } = useStores({ filters: { publicationState: undefined}})
  
  // const areLoading = isLoadingCategories || isHandlingCategory
  return (
    <React.Fragment>
      <Header.BreadCrumb color="secondary" isDark={false}/>
      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <HomeStoresTableCard
              stores={stores}
              refreshStores={refresh}
              isLoading={isLoadingStores}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default Home;