import React from "react";
import { Modal } from "@general-backoffice/core";


const DeleteAdvantageModal = ({ category, close, remove, isLoading }) => {
  return (
    <Modal.DeleteConfirm
      isOpen={!!category}
      isLoading={isLoading}
      toggleOpen={close}
      titleDelete="Eliminar categoría"
      descriptionDelete={<>Estás a punto de eliminar la categoría <span className="font-weight-bold">"{category?.name}"</span>.</>}
      closeText="Cancelar"
      buttonDelete={{ children: "Eliminar", onClick: remove }}
    />
  );
}

export default DeleteAdvantageModal;