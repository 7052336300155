import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { Header } from "@general-backoffice/core/index";
import { useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";
import _ from "lodash";

import {productRequests} from "../../../../api/Api";
import useProduct from "../../../../components/products/hooks/useProduct";

// Components
import ProductDetailsCard from "../../../../components/products/ProductDetailsCard";
import UpdateProductModal from "../../../../components/products/UpdateProductModal";


const defaultStore = {
  publishedAt: null,
}

const Product = props => {
  const navigate = useNavigate()
  const store = useSelector(({ sessionState }) => sessionState.store)

  const { identifier = props?.identifier } = useParams()
  const {
    product,
    setProduct,
    update: updateProduct,
    changeStatus,
    changeVisibility,
    load,
    isLoading
  } = useProduct(identifier, {
    params: {
      populate: productRequests.defaultPopulate.concat(['store'])
    },
    onLoadError: !!identifier && (() => navigate(-1, { replace: true }))
  })

  useEffect(() => {
    if(!identifier) setProduct({...defaultStore, store})
    // eslint-disable-next-line
  }, [identifier])


  const [confirmProductUpdate, setConfirmProductUpdate] = useState(false)
  

  return (
    <React.Fragment>

      <UpdateProductModal
        isOpen={confirmProductUpdate}
        product={product}
        close={() => setConfirmProductUpdate(null)}
        update={() => {
          const prodCopy = _.cloneWith(product)
          updateProduct({
            ...prodCopy,
            publishedAt: null,
            validation: 'pending',
            warning: null
          }).then(async updatedProduct => {
            if(updatedProduct.identifier === identifier) await load()
            else navigate(`/dashboard/products/${updatedProduct.identifier}`, { replace: true })
            setConfirmProductUpdate(null)
          })
        }}
        isLoading={isLoading}
      />

      <Header.BreadCrumb color="secondary" isDark={false}/>
      <Container className="mt--6" fluid>
        <Row>
          <Col xs={12}>
            <ProductDetailsCard
              product={product}
              setProduct={setProduct}
              changeVisibility={changeVisibility}
              changeStatus={(...props) => changeStatus(...props).then(load).catch()}
              updateProduct={() => product?.id ?
                  setConfirmProductUpdate(true)
                :
                  updateProduct()
                    .then(newProduct => navigate(`/dashboard/products/${newProduct.identifier}`, { replace: true }))
              }
              isLoading={isLoading}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default Product;