import { notify } from "@general-backoffice/core";
import { getStrapiFileId } from "@libraries/strapi-4-utils";

import {categoryRequests, uploadFile} from "../../../api/Api"
import useStrapi4GetOne from "../../hooks/useStrapi4GetOne";

// import { submitLocalizations } from "../../utils/localizations";
import handleError from "../../utils/handleError";


const useCategory = (id, params) => {
  const {
    item: category,
    setItem: setCategory,
    ...other // isLoading, setLoading, load, remove
  } = useStrapi4GetOne({
    id,
    params,
    loadItem: loadCategory,
    submitItem: submitCategory,
    deleteItem: deleteCategory
  })

  return {
    category,
    setCategory,
    ...other
  }
}


export const loadCategory = async (id, params) => {
  try {
    return parseInt(id) ?
      await categoryRequests.get(id, params)
    :
      await categoryRequests.getByIdentifier(id, params)
  } catch (e) {
    handleError(e?.message || "Error al cargar la categoría")
  }
}

export const submitCategory = async category => {
  const isNew = !category?.id
  const errMsg = `${isNew ? 'Creación' : 'Modificación'} de categoría fallida`
  if (!category) throw new Error(errMsg)
  try {
    const {
      id,
      name,
      image,
      banner,
      store,
      // localizations, // name
    } = category

    const body = {
      name: name.trim(),
      identifier: encodeURIComponent(name.trim().replaceAll(' ', '_').toLowerCase()),
      store: store?.id || store,
      image: await getStrapiFileId(image, uploadFile),
      banner: await getStrapiFileId(banner, uploadFile),
    }

    const [res] = isNew ?
      await categoryRequests.create(body)
      :
      await categoryRequests.update(id, body)

    // Update or create every localization needed
    // const defaultId = isNew ? res.id : id
    // if(localizations) await submitLocalizations(localizations, defaultId, categoryRequests.update, categoryRequests.localize)

    notify.success(`${isNew ? 'Creación' : 'Modificación'} de categoría finalizada`)
    return res
  } catch (e) {
    handleError(e?.message || errMsg)
  }
}

export const deleteCategory = async category => {
  const errMsg = "Error al intentar eliminar categoria"
  if (!category?.id) throw new Error(errMsg)
  try {
    await categoryRequests.delete(category.id)
    notify.success("Eliminación de categoría finalizada correctamente")
  } catch (e) {
    handleError(e?.message || errMsg)
  }
}




export default useCategory;