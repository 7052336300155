export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const CHANGE_STORE = "CHANGE_STORE";

export const login = (token, user) => ({
  type: LOGIN,
  payload: { token, user }
});

export const logout = () => ({
  type: LOGOUT,
  payload: null
});

export const setStore = (store, stores) => ({
  type: CHANGE_STORE,
  payload: { store, stores }
});